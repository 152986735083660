import React, { ReactChild, ReactChildren, ReactNode } from "react";
import { View, ViewStyle } from "react-native";
import { Outlines, Sizing, useTheme } from "../theme";
import { SubHeader, Body, Highlight } from "../theme/components";

export interface RowEntry {
  desc: string;
  value: string;
}
export const TableRow = ({ entry }: { entry: RowEntry }) => {
  const { colors } = useTheme();

  return (
    <View style={{ flexDirection: "row" }}>
      <View
        style={{
          width: Sizing.cs(270),
          paddingVertical: Sizing.layout.x10,
          paddingHorizontal: Sizing.layout.x20,
          borderColor: colors.background.s200,
          borderRightWidth: Outlines.borderWidth.thick,
        }}
      >
        <Highlight>{entry.desc}:</Highlight>
      </View>
      <View
        style={{
          flex: 1,
          paddingVertical: Sizing.layout.x10,
          paddingHorizontal: Sizing.layout.x20,
        }}
      >
        <Body>{entry.value}</Body>
      </View>
    </View>
  );
};

export interface TableProps {
  children?: ReactNode;
  rows: RowEntry[];
  containerStyle?: ViewStyle;
  rowStyle?: ViewStyle;
  title?: string;
}

export const Table = (props: TableProps) => {
  const { colors } = useTheme();
  const filledRows = props.rows.filter((entry) => Boolean(entry.value));

  return (
    <View
      style={[
        {
          overflow: "hidden",
          backgroundColor: colors.background.s200,
          shadowColor: colors.primary.s200,
          ...Outlines.shadow.outer,
          borderRadius: Outlines.borderRadius.base,
          borderWidth: Outlines.borderWidth.thick,
          borderColor: colors.background.s200,
        },
        props.containerStyle,
      ]}
    >
      {props.title ? (
        <View style={{ padding: Sizing.layout.x10 }}>
          <SubHeader text={props.title} />
        </View>
      ) : null}
      {filledRows.map((entry, index) => {
        const backgroundColor =
          index % 2 == 0 ? colors.background.s100 : colors.background.s200;
        return (
          <View key={index} style={[{ backgroundColor }, props.rowStyle]}>
            <TableRow entry={entry} />
          </View>
        );
      })}
    </View>
  );
};
