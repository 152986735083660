import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import tinycolor from "tinycolor2";

// Simple wrapper to generate a random color as hex string.
export const randColor = () => {
    return tinycolor.random().toHexString();
};

// Sums up an array of color strings, weighting each the same.
// Return the result as hex string to make usage with other things easier.
export const mixColors = (colors: string[]) => {
    // Sum up every value in the array.
    let sum = tinycolor("#000000").toRgb();

    // Fun fact: this is an impure function call because it references sum.
    // Calling reduce instead would be pure, but it doesn't matter here.
    colors.forEach((element) => {
        let rgb = tinycolor(element).toRgb();
        sum.r += rgb.r;
        sum.g += rgb.g;
        sum.b += rgb.b;
    });

    sum.r /= colors.length;
    sum.g /= colors.length;
    sum.b /= colors.length;

    return tinycolor(sum).toHexString();
};

// Create a nested object state to reuse logic for alice and bob individually
export interface Person {
    secret: string;
    mixed: string;
    received: string;
    result: string;
    hasStarted: boolean;
    hasPicked: boolean;
    hasMixed: boolean;
    hasSent: boolean;
    hasReceived: boolean;
    hasResult: boolean;
};
export interface MixState {
    generator: string;
    bob: Person;
    alice: Person;
};
export type Target = "bob" | "alice"

// Initial values to (re-)set the reducer
export const initialColor = "transparent";
export const initialPerson: Person = {
    secret: initialColor,
    mixed: initialColor,
    received: initialColor,
    result: initialColor,
    hasStarted: false,
    hasPicked: false,
    hasMixed: false,
    hasSent: false,
    hasReceived: false,
    hasResult: false,
};
export const initialMixState: MixState = {
    generator: randColor(),
    bob: { ...initialPerson },
    alice: { ...initialPerson },
};
export const mixSlice = createSlice({
    name: "mixer",
    initialState: initialMixState,
    reducers: {
        reset: (state) => {
            return {
                ...initialMixState,
            };
        },
        generate: (state) => {
            state.generator = randColor();
        },
        start: (state, action: PayloadAction<Target>) => {
            const target = action.payload;
            state[target].hasStarted = true;
        },
        pick: (state, action: PayloadAction<{ target: Target, color: string }>) => {
            const { target, color } = action.payload;
            state[target].secret = color;
            state[target].hasPicked = true;
        },
        mix: (state, action: PayloadAction<Target>) => {
            const target = action.payload;
            state[target].mixed = mixColors([state.generator, state[target].secret])
            state[target].hasMixed = true;
        },
        send: (state, action: PayloadAction<Target>) => {
            const target = action.payload;
            const other = target === "bob" ? "alice" : "bob";
            state[target].hasSent = true;
            state[other].hasReceived = true;
            state[other].received = state[target].mixed;
        },
        finish: (state, action: PayloadAction<Target>) => {
            const target = action.payload;
            state[target].hasResult = true;
            state[target].result = mixColors([
                state.generator,
                state.bob.secret,
                state.alice.secret,
            ]);
        }
    },
});

export const mixActions = mixSlice.actions;
export default mixSlice.reducer;
