import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { IconButton } from "../components/IconButton";
import { InputBar } from "../components/InputBar";
import { Sizing } from "../theme";
import { Body, Hint, Card, Content, } from "../theme/components";
import { useDeviceOrientation } from "@react-native-community/hooks";
import { Spacer } from "../components/Spacer";
import {
  checkPasswordAsync,
  translateDictionary,
  translatePattern,
  translateScore,
  CheckResult,
  translateGuesses,
  translateSuggestions,
  translateCracktime,
} from "../util/zxcvbnTranslations";
import { RowEntry, Table } from "../components/Table";
import { ScrollView } from "react-native-gesture-handler";

const styles = StyleSheet.create({
  input: {
    width: Sizing.cs(600),
    alignSelf: "center",
  },
});

const TaskControls = ({
  setResult,
}: {
  setResult: (result?: CheckResult) => void;
}) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      let controller: AbortController = new AbortController();
      let { signal } = controller;

      const check = async (password: string) => {
        try {
          const result = await checkPasswordAsync(password);
          setResult(result);
          setLoading(false);
        } catch (e) {
          if (!signal?.aborted) {
            console.log(e);
          }
        }
      };

      check(password);
      return () => controller.abort();
    }
  }, [loading]);

  const startChecking = () => {
    setLoading(true);
  };

  return (
    <Card style={{ maxWidth: Sizing.cs(700), alignSelf: "center" }}>
      <TaskDescription />
      <Spacer size="medium" />
      <InputBar
        placeholder="Wie stark ist ein Passwort?"
        maxLength={32}
        style={styles.input}
        outerRight={
          <IconButton
            gradient
            onPress={startChecking}
            disabled={loading}
            loading={loading}
            icon="CheckPwStrength"
          />
        }
        onChangeText={setPassword}
        value={password}
        onSubmitEditing={startChecking}
      />
    </Card>
  );
};

const TaskDescription = () => {
  return (
    <View style={{}}>
      <Body text="Denke dir Passwörter aus und untersuche welche Merkmale sie stark bzw. schwach machen." />
      <Spacer size="tiny" />
      <Hint text="Versuche clevere Ideen dir das Merken erleichtern könnten." />
    </View>
  );
};

const BaseFeedback = ({ result }: { result: CheckResult }) => {
  const rows: RowEntry[] = [
    { desc: "Passwort", value: result.password },
    { desc: "Bewertung", value: translateScore(result.score) },
    {
      desc: "Anzahl Versuche",
      value: translateGuesses(result.guesses),
    },
    {
      desc: "Offline-Attacke",
      value: translateCracktime(result.crackTimesDisplay.offlineSlowHashing1e4PerSecond),
    },
    {
      desc: "Online-Attacke",
      value: translateCracktime(result.crackTimesDisplay.onlineNoThrottling10PerSecond),
    },
    { desc: "Warnung", value: result.feedback.warning },
    {
      desc: "Empfehlungen",
      value: translateSuggestions(result.feedback.suggestions),
    },
  ];

  return (
    <Table
      title="Gesamtergebnis"
      containerStyle={{
        width: "100%",
        maxWidth: Sizing.cs(800),
      }}
      rows={rows}
    />
  );
};

const ExtendedFeedback = ({ matches }: { matches: any[] }) => {
  return (
    <View style={{ width: "100%", maxWidth: Sizing.cs(800) }}>
      {matches.map((match, index) => (
        <View key={index} style={{ padding: Sizing.layout.x5 }}>
          <Match match={match} />
          <Spacer size="medium" />
        </View>
      ))}
    </View>
  );
};

const Match = ({ match }: { match: any }) => {
  const rows: RowEntry[] = [
    { desc: "Benötigte Guesses", value: translateGuesses(match.guesses) },
    { desc: "Geknackt durch", value: translatePattern(match.pattern) },
    {
      desc: "In Wörterbuch",
      value: translateDictionary(match.dictionaryName),
    },
  ];

  return <Table title={"Teilwort: " + match.token} rows={rows} />;
};

export const StrengthScreen = () => {
  const [result, setResult] = useState<CheckResult>();
  const isLandscape = useDeviceOrientation().landscape;

  return (
    <ScrollView >
      <Content>
        <TaskControls setResult={setResult} />
        <Spacer size="medium" />
        {result && (
          <View
            style={{
              flexDirection: isLandscape ? "row" : "column",
              width: "100%",
            }}
          >
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: isLandscape ? undefined : "center",
              }}
            >
              <BaseFeedback result={result} />
            </View>
            <Spacer size="medium" />
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: isLandscape ? undefined : "center",
              }}
            >
              <ExtendedFeedback matches={result.sequence} />
            </View>
          </View>
        )}
      </Content>
    </ScrollView>
  );
};
