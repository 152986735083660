import React from "react";
import { useTheme, Sizing, Outlines } from "../theme";
import { IconName, Icons, IconSize } from "../theme/icons";
import { Button, ButtonProps } from "./Button";
import { GradientButton } from "./GradientButton";
import { ActivityIndicator, StyleSheet } from "react-native";
import { Color } from "../theme/colors";

export const iconButtonSize: Record<IconSize, number> = {
  huge:
    Sizing.layout.x70,
  large:
    Sizing.layout.x60,
  medium:
    Sizing.layout.x50,
  small:
    Sizing.layout.x30,
  tiny:
    Sizing.layout.x10,
};

export interface IconButtonProps extends ButtonProps {
  gradient?: boolean;
  icon?: IconName;
  loading?: boolean;
  size?: IconSize;
  backgroundColor?: Color
}

export const IconButton = ({
  gradient = false,
  disabled,
  style,
  icon,
  loading,
  size,
  backgroundColor,
  ...rest
}: IconButtonProps) => {
  const { colors, baseOnContrast } = useTheme();
  size = size ?? "huge";

  const Content = ({ color }: { color: string }) => {
    return loading ? (
      <ActivityIndicator color={color} />
    ) : (
      icon ? Icons[icon]({ color, size }) : null
    )
  }

  const buttonProps: ButtonProps = {
    disabled,
    style: [{ width: iconButtonSize[size], height: iconButtonSize[size] }, styles.iconButton, style],
    ...rest
  }

  return gradient ? (
    <GradientButton {...buttonProps}>
      <Content color={baseOnContrast(backgroundColor ?? colors.primary.s200)} />
    </GradientButton>
  ) : (
    <Button {...buttonProps} >
      <Content color={baseOnContrast(backgroundColor ?? colors.background.s300)} />
    </Button>
  );
};

const styles = StyleSheet.create({
  iconButton: {
    aspectRatio: 1,
    borderRadius: Outlines.borderRadius.full,
    justifyContent: "center",
    alignItems: "center",
  },
});
