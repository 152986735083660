import React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { useTheme } from "../theme";
import { SideMenu } from "./SideMenu";

export default () => {
  const { colors } = useTheme();

  return (
    <NavigationContainer
      theme={{
        dark: colors.mode === "dark",
        colors: {
          primary: colors.primary.s200,
          background: colors.background.s300,
          card: colors.background.s200,
          text: colors.foreground.s300,
          border: colors.foreground.s200,
          notification: colors.secondary.s200,
        },
      }}
    >
      <SideMenu />
    </NavigationContainer>
  );
};
