import { useTabNavigation } from "../navigation/types";
import { chatActions, cryptoActions, useAppDispatch, useAppSelector } from "../store";
import { BigInteger } from "big-integer";
import { notify } from "../styles/notification";

export const useOpenChat = () => {
    const nav = useTabNavigation();
    const openChat = () => nav.navigate("ChatScreen");
    return openChat;
}

export const useCanSend = () => {
    const status = useAppSelector((state) => state.chat.status);

    const canSend = () => {
        if (status !== "connected") {
            notify({
                type: "danger",
                message: "Nicht Verbunden",
                description: "Du musst eingeloggt sein um Nachrichten zu senden"
            })
            return false;
        }
        return true;
    }

    return canSend;
}

export const useSend = () => {
    const dispatch = useAppDispatch();
    const canSend = useCanSend();

    const send = (message: string) => {
        if (!canSend()) {
            return false;
        }
        dispatch(chatActions.send(message));
        return true;
    }
    return send;
}

export const useSendMix = () => {
    const send = useSend();
    const mix = useAppSelector((state) => state.crypto.mix);
    const openChat = useOpenChat();

    const sendMix = () => {
        if (!mix) {
            notify({
                type: "danger",
                message: "Mix nicht gesetzt",
                description: "Du musst erst einen Mix berechnen um ihn zu teilen"
            })
            return false;
        }
        if (!send(mix.toString())) {
            openChat();
            return false;
        }
        notify({
            type: "info",
            message: "Mix geteilt"
        })
        openChat();
        return true;
    };

    return sendMix;
}

export const useCanPickPartner = () => {
    const mix = useAppSelector((state) => state.crypto.mix);

    const canPickPartner = () => {
        if (!mix) {
            notify({
                type: "danger",
                message: "Mix nicht gesetzt",
                description: "Teile erst deinen eigenen Mix bevor du einen Partner wählst"
            })
            return false;
        }
        return true;
    }

    return canPickPartner
}

export const usePickPartner = () => {
    const dispatch = useAppDispatch();
    const canPick = useCanPickPartner();

    const pick = (partnerName: string, partnerMix: BigInteger) => {
        if (!canPick()) {
            return false;
        }

        dispatch(cryptoActions.setPartner(partnerMix));
        dispatch(chatActions.setPartner(partnerName));
        notify({
            message: "Dein Partner ist: '" + partnerName + "'!",
            description: "Schlüssel auf '" + partnerMix + "' gesetzt",
            type: "info",
        })
        return true;
    }

    return pick
};
