import React, { useRef, useState } from "react";
import {
  FlatList,
  FlatListProps,
  NativeScrollEvent,
  Platform,
} from "react-native";

export interface AutoScrollListProps<T> extends FlatListProps<T> {
  alwaysScroll?: boolean;
}

export function AutoScrollList<T>({
  style,
  alwaysScroll,
  ...props
}: AutoScrollListProps<T>) {
  const listRef = useRef<FlatList>(null);
  const [shouldScroll, setShouldScroll] = useState(true);

  /**
   * Check if the user scrolled to the end or not
   * Since there is no direct "currentoffset", we need to
   * calculate it ourself using the contentSize and layoutMeasurements
   */
  const checkScrollPosition = (ev: NativeScrollEvent) => {
    const threshold = 1;
    const endOffset = ev.contentSize.height - ev.layoutMeasurement.height;
    const currentOffset = ev.contentOffset.y + threshold;
    setShouldScroll(currentOffset >= endOffset);
  };

  return (
    <FlatList
      {...props}
      ref={listRef}
      onScrollEndDrag={(ev) => {
        // Check the position after a user has ended scrolling
        checkScrollPosition(ev.nativeEvent);
      }}
      onScrollBeginDrag={() => {
        // Prevent automatic scrolling while the user tries to scroll
        setShouldScroll(false);
      }}
      onScroll={(ev) => {
        // The begin and end drag events are not working on the web
        // so we fallback to checking every "scroll" instead
        if (Platform.OS === "web") {
          checkScrollPosition(ev.nativeEvent);
        }
      }}
      onContentSizeChange={(w, offset) => {
        // Fires when a new message is added unless the user scrolled
        // manually before
        if (shouldScroll || alwaysScroll) {
          // Note: the scrollToEnd function is janky, scroll to the
          // new height offset instead.
          listRef?.current?.scrollToOffset({
            animated: true,
            offset,
          });
        }
      }}
    />
  );
}
