import { MessageOptions, showMessage } from "react-native-flash-message";
import { Typography } from "../theme";

export const NotificationBase: MessageOptions = {
    message: "",
    textStyle: Typography.text.body,
    titleStyle: Typography.text.highlight,
    icon: "auto",
    duration: 2500,
};

export const notify = (options: MessageOptions) => {
    showMessage({
        ...NotificationBase,
        ...options,
    })
}