import { createSlice, PayloadAction, Middleware } from "@reduxjs/toolkit"
import { ChatSocket } from "../api/chatsocket"
import { error_reason } from "../api/helpers"
import { ErrorCode, UserInfo, ChatEvent, IncomingType } from "../api/types"

export interface ChatState {
    status: "establishing" | "connected" | "disconnected",
    user: string,
    partnerName: string,
    feed: ChatEvent[],
    error: string,
    name: string,
}

const dummyMessages = [
    "Test",
    "Ganz langer toller Test der über mehrere Zeilen gehen sollte, damit besser getestet werden kann",
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia recusandae modi delectus necessitatibus molestiae, dolore officia aut. Veritatis provident ea consequatur, laboriosam corporis cum nam enim earum nemo placeat quisquam",
    "Noch so ein Test",
].map((msg, index): ChatEvent => ({
    type: IncomingType.ChatMessage,
    payload: {
        id: index.toString(),
        author: "Dummy",
        body: msg,
        received_at: Date.now(),
    }
}))

export const initialState: ChatState = {
    status: "disconnected",
    user: "",
    error: "",
    name: "",
    partnerName: "",
    feed: __DEV__ ? dummyMessages : [],
}

export const chatSlice = createSlice({
    name: "chat",
    initialState,
    // NOTE: Additional websocket logic will be handled by SocketMiddleware
    reducers: {
        send: (state, action: PayloadAction<string>) => { },
        connect: (state) => {
            state.status = "establishing"
        },
        onRegistered: (state, action: PayloadAction<UserInfo>) => {
            state.status = "connected"
            state.user = action.payload
        },
        onError: (state, action: PayloadAction<ErrorCode>) => {
            state.status = "disconnected"
            state.error = error_reason(action.payload)
        },
        onEvent: (state, action: PayloadAction<ChatEvent>) => {
            state.feed.push(action.payload)
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload
        },
        setPartner: (state, action: PayloadAction<string>) => {
            state.partnerName = action.payload
        }
    },
})

/**
 * Binds a socket to the apropiate reducer actions in the store.
 * 
 * See examples here:
 * https://wanago.io/2021/12/20/redux-middleware-websockets/
 * https://dev.to/aduranil/how-to-use-websockets-with-redux-a-step-by-step-guide-to-writing-understanding-connecting-socket-middleware-to-your-project-km3
 */
export const socketMiddleware: Middleware = store => {
    let socket: ChatSocket;

    return next => action => {
        // Create a new connection and bind the receiving calls to the store
        if (chatActions.connect.match(action)) {
            socket = new ChatSocket({
                username: store.getState().chat.name,
                onError: (error) => store.dispatch(chatActions.onError(error)),
                onConnected: (info) => store.dispatch(chatActions.onRegistered(info)),
                onChatEvent: (event) => store.dispatch(chatActions.onEvent(event)),
            });
        }

        // Forward the message to the connection
        if (chatActions.send.match(action)) {
            socket.postMessage(action.payload);
        }

        return next(action)
    }
}

export const chatActions = chatSlice.actions
export default chatSlice.reducer
