import React from "react";
import { View, ViewStyle } from "react-native";
import { Outlines, Sizing } from "../theme";
import { Body, Hint, Chip } from "../theme/components";
import { PasswordGuess } from "../hooks/usePasswordGuessing";

const rankColumnWidth = Sizing.cs(120);
const passwordColumnWidth = Sizing.cs(200);
const scoreColumnWidth = Sizing.cs(120);
export const entryWidth = rankColumnWidth + scoreColumnWidth + passwordColumnWidth;

export const PasswordGuessEntry = ({ item }: { item: PasswordGuess }) => {
  const cell: ViewStyle = {
    height: Sizing.layout.x70,
    justifyContent: "center",
  };
  return (
    <Chip
      style={{
        margin: Sizing.layout.x10,
        borderRadius: Outlines.borderRadius.small,
      }}
    >
      <View
        style={[
          cell,
          {
            width: rankColumnWidth,
            alignItems: "center",
          },
        ]}
      >
        <Body>{item.rank}.</Body>
      </View>
      <View
        style={[
          cell,
          {
            width: passwordColumnWidth,
          },
        ]}
      >
        {item.hidden ? <Hint text="???" /> : <Body text={item.password} />}
      </View>
      <View
        style={[
          cell,
          {
            width: scoreColumnWidth,
            alignItems: "center",
          },
        ]}
      >
        {item.hidden ? <></> : <Hint>{item.score}</Hint>}
      </View>
    </Chip>
  );
};
