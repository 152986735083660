import React from "react";
import { createDrawerNavigator, DrawerItem } from "@react-navigation/drawer";
import { KeyExchangeTabs } from "./KeyExchangeTabs";
import { HeaderBar } from "./Header";
import { PwCrackingScreen1 } from "../screens/PwCrackingScreen1";
import { PwCrackingScreen2 } from "../screens/PwCrackingScreen2";
import { AboutScreen } from "../screens/AboutScreen";
import { Outlines, Sizing, useTheme } from "../theme";
import { Image, ScrollView, View } from "react-native";
import { Body, Highlight } from "../theme/components";
import { StrengthScreen } from "../screens/StrengthScreen";
import { Spacer } from "../components/Spacer";
import { SideMenuParamList } from "./types";

export const Drawer = createDrawerNavigator<SideMenuParamList>();

export const SideMenu = () => {
  const { colors } = useTheme();

  return (
    <Drawer.Navigator
      initialRouteName="About"
      screenOptions={{
        header: ({ navigation, route, options }) => (
          <HeaderBar title={options.drawerLabel as string} />
        ),
        drawerStyle: { width: Sizing.cs(460) },
      }}
      drawerContent={(props) => {
        // Super hacky workaround because React Naviagtion Drawer don't allow
        // Sections or anything like that.
        // This is awful, but it works.
        const { state, descriptors, navigation } = props;
        const Item = (props: { route: any }) => {
          const { drawerLabel, drawerActiveTintColor } =
            descriptors[props.route.key].options;
          return (
            <DrawerItem
              key={props.route.key}
              label={({ color }) => (
                <Highlight color={color}>{drawerLabel}</Highlight>
              )}
              focused={
                state.routes.findIndex((e) => e.name === props.route.name) ===
                state.index
              }
              activeTintColor={drawerActiveTintColor}
              onPress={() => navigation.navigate(props.route.name)}
            />
          );
        };

        const Section = (props: { text: string }) => (
          <View
            style={{
              borderBottomColor: colors.foreground.s200,
              borderBottomWidth: Outlines.borderWidth.hairline,
            }}
          >
            <Highlight text={props.text} />
          </View>
        );

        return (
          <View style={{ flex: 1 }}>
            <Image
              style={{ width: "100%", height: Sizing.cs(200) }}
              resizeMode="cover"
              source={require("../../assets/locks.jpg")}
            />
            <Spacer size="medium" />
            <ScrollView
              contentContainerStyle={{ paddingHorizontal: Sizing.layout.x30 }}
            >
              <Section text="Diffie-Hellman Workshop" />
              <Item route={state.routes[0]} />
              <Section text="MINT-Ferienprogramm" />
              <Item route={state.routes[1]} />
              <Item route={state.routes[2]} />
              <Item route={state.routes[3]} />
              <Section text="Allgemein" />
              <Item route={state.routes[4]} />
            </ScrollView>
          </View>
        );
      }}
    >
      <Drawer.Screen
        name="KeyExchange"
        component={KeyExchangeTabs}
        options={{ drawerLabel: "Schlüsseltausch" }}
      />
      <Drawer.Screen
        name="PwCracking1"
        component={PwCrackingScreen1}
        options={{ drawerLabel: "Passwort-Cracking 1" }}
      />
      <Drawer.Screen
        name="StrengthEvaluator"
        component={StrengthScreen}
        options={{ drawerLabel: "Passwortstärke" }}
      />
      <Drawer.Screen
        name="PwCracking2"
        component={PwCrackingScreen2}
        options={{ drawerLabel: "Passwort-Cracking 2" }}
      />
      <Drawer.Screen
        name="About"
        component={AboutScreen}
        options={{ drawerLabel: "Impressum" }}
      />
    </Drawer.Navigator>
  );
};
