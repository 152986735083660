import React from "react";
import { StyleSheet, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { useTheme, Outlines } from "../theme";
import { Button, ButtonProps } from "./Button";

export interface GradientButtonProps extends ButtonProps {
  outlined?: boolean;
}

// Wraps a linear gradient around a touchableopacity to simulate a gradient background
export const GradientButton = ({
  style,
  outlined: filled,
  disabled,
  ...rest
}: GradientButtonProps) => {
  const { colors, baseOnContrast } = useTheme();
  const opacity = disabled ? 0.8 : 1;

  return (
    <View style={style}>
      <LinearGradient
        // Use the theme defaults if not provided
        start={[0.2, 0.2]}
        end={[0.8, 0.8]}
        colors={[
          colors.primary.s200,
          colors.secondary.s200,
          colors.primary.s200,
        ]}
        style={[
          styles.common,
          styles.gradient,
          {
            opacity,
            shadowColor: colors.primary.s200,
          },
          Outlines.shadow.inner,
        ]}
      >
        <Button
          disabled={disabled}
          style={[
            styles.common,
            styles.button,
            {
              backgroundColor: filled ? colors.background.s300 : undefined,
            },
          ]}
          titleStyle={{
            color: filled
              ? colors.foreground.s300
              : baseOnContrast(colors.primary.s200),
          }}
          {...rest}
        />
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  common: {
    borderRadius: Outlines.borderRadius.full,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  gradient: {
    padding: Outlines.borderWidth.base,
  },
  button: {},
});
