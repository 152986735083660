import { ErrorCode } from "./types";

/**
 * Translate the given error into a readable reason string.
 * TODO: Replace this with the i18n implementation.
 */
export const error_reason = (e: ErrorCode) => {
    switch (e) {
        case ErrorCode.ConnectionLost:
            return "Fehler: Verbindung zum Server konnte nicht aufgebaut werden";
        case ErrorCode.NameInvalid:
            return "Fehler: Name enthält ungültige Zeichen";
        case ErrorCode.NameTaken:
            return "Fehler: Name ist bereits vergeben";
        case ErrorCode.NameTooShort:
            return "Fehler: Name ist zu kurz";
        case ErrorCode.InputInvalid:
            return "Interner Fehler: Protokollfehler";
        case ErrorCode.TimeOut:
            return "Zu lange inaktiv"
        default:
            return "Unbekannter interner Fehler";
    }
}