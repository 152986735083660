import React from "react";
import { StyleSheet, TextInputProps } from "react-native";
import bigInt, { BigInteger } from "big-integer";
import { Sizing } from "../theme";
import { Input } from "./Input";

export interface BigIntInputProps extends TextInputProps {
  setInt?: (val?: BigInteger) => void;
  intValue?: BigInteger;
}

export const BigIntInput = ({
  setInt,
  intValue,
  style,
  ...rest
}: BigIntInputProps) => {
  // We only want to use setInt if an actual number was used as input.
  const onChange = (text: string) => {
    if (setInt) {
      if (/^\d+$/.test(text)) {
        const num = bigInt(text);
        num.greater(0) ? setInt(num) : setInt(undefined);
      } else {
        setInt(undefined);
      }
    }
  };

  return (
    <Input
      {...rest}
      style={[styles.input, style]}
      value={intValue ? intValue.toString() : ""}
      onChangeText={onChange}
      keyboardType="numeric"
      editable={setInt ? true : false}
    />
  );
};

const styles = StyleSheet.create({
  input: {
    flex: 1,
    minWidth: 0, // Needs to be overridden for web
    textAlign: "center",
    height: Sizing.layout.x70,
  },
});
