import {
  zxcvbn,
  zxcvbnAsync,
  zxcvbnOptions,
  ZxcvbnResult,
} from "@zxcvbn-ts/core";
import zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import zxcvbnDePackage from "@zxcvbn-ts/language-de";
import {
  DictionaryNames,
  MatchExtended,
  OptionsType,
  Pattern,
} from "@zxcvbn-ts/core/dist/types";

const options: OptionsType = {
  // Configure german feedback
  translations: zxcvbnDePackage.translations,
  // Configure zxcvbn to use english and german dictionaries
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
    ...zxcvbnDePackage.dictionary,
  },
  // Use adjacency graphs for keyboard patterns
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  useLevenshteinDistance: true,
};
zxcvbnOptions.setOptions(options);

// Reexport as alias
export const checkPasswordAsync = zxcvbnAsync;
export const checkPassword = zxcvbn;
export type CheckResult = ZxcvbnResult;

export function translateScore(score: number): string {
  switch (score) {
    case 0:
      return "Sehr schwach";
    case 1:
      return "Schwach";
    case 2:
      return "Medium";
    case 3:
      return "Stark";
    case 4:
      return "Sehr stark";
    default:
      return "Undefiniert";
  }
};

export function translateSuggestions(suggestions: string[]): string {
  if (suggestions.length == 0) return "";
  return suggestions.join("\n");
}

const MILLION = 1000000;
const BILLION = 1000000000;               //         1.000.000.000 (9)
const TRILLION = 1000000000000;           //     1.000.000.000.000 (12)
const QUADRILLION = 1000000000000000;     // 1.000.000.000.000.000 (15)
const MAX = 9007199254740992;             // 9.007.199.254.740.992 (15)
export function translateGuesses(guesses: number): string {
  if (guesses < MILLION) {
    return guesses.toString();
  }
  if (guesses < BILLION) {
    return "~" + Math.floor(guesses / MILLION).toString() + " Millionen Versuche";
  }
  if (guesses < TRILLION) {
    return "~" + Math.floor(guesses / BILLION).toString() + " Milliarden Versuche";
  }
  if (guesses < QUADRILLION) {
    return "~" + Math.floor(guesses / TRILLION).toString() + " Billionen Versuche";
  }
  return "~" + Math.floor(guesses / QUADRILLION).toString() + " Billiarden Versuche";
}

export function translateDictionary(dict: DictionaryNames): string {
  switch (dict) {
    case 'passwords': return "Bekannte Passwortlisten";
    case 'commonWords': return "Gebräuchliche Wörter";
    case 'firstnames': return "Häufige Vornamen";
    case 'lastnames': return "Häufige Nachnamen";
    case 'wikipedia': return "Wikipedia Listen (z.B. beliebte Filme)";
    case 'userInputs': return "Spezielle Nutzereingaben";
    default: return dict;
  }
}

export function translatePattern(pattern: Pattern): string {
  switch (pattern) {
    case "dictionary":
      return "Wortbuch-Attacke";
    case "spatial":
      return "Tastaturmuster (z.B. qwerty)";
    case "repeat":
      return "Wiederholungsmuster (z.B. aaaaaa)";
    case "sequence":
      return "Abfolge (z.B abcdef, 654321)";
    case "regex":
      return "Vordefiniertes Muster (regex)";
    case "date":
      return "Als Datum erkannt";
    case "bruteforce":
      return "Bruteforce";
    default:
      return pattern;
  }
}

export function translateCracktime(time: string): string {
  return "Geknackt in " + time;
}
