import React, { useContext } from "react";
import { StyleSheet, View } from "react-native";
import { Sizing, ThemeContext, } from "../theme";
import { useDeviceOrientation } from "@react-native-community/hooks";
import { DrawerActions, useNavigation } from "@react-navigation/native";
import { Button } from "../components/Button";
import { Header, Body } from "../theme/components";
import { Icons } from "../theme/icons";

export const MenuButton = () => {
  const navigation = useNavigation();
  const toggleMenu = () => navigation.dispatch(DrawerActions.toggleDrawer());
  const isLandscape = useDeviceOrientation().landscape;

  return (
    <Button style={styles.button} onPress={toggleMenu}>
      <Icons.Menu size="huge" />
      {isLandscape && <Body style={styles.buttonText} text="Menü" />}
    </Button>
  );
};

export const ThemeButton = () => {
  const { colors, toggleTheme } = useContext(ThemeContext);
  const isLandscape = useDeviceOrientation().landscape;

  return (
    <Button style={styles.button} onPress={toggleTheme}>
      {isLandscape && <Body style={styles.buttonText} text="Farbmodus" />}
      {colors.mode === "light" ? (
        <Icons.DarkMode size="huge" />
      ) : (
        <Icons.LightMode size="huge" />
      )}
    </Button>
  );
};

export interface HeaderProps {
  title: string;
}

export const HeaderBar = ({ title }: HeaderProps) => {
  const { colors } = useContext(ThemeContext);

  return (
    <View
      style={[
        {
          backgroundColor: colors.background.s200,
        },
        styles.row,
      ]}
    >
      <View style={styles.buttonContainer}>
        <MenuButton />
      </View>
      <Header text={title} style={styles.title} />
      <View
        style={[
          styles.buttonContainer,
          {
            alignItems: "flex-end",
          },
        ]}
      >
        <ThemeButton />
      </View>
    </View>
  );
};

export const styles = StyleSheet.create({
  row: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: Sizing.layout.x10,
    paddingHorizontal: Sizing.layout.x50,
  },
  title: {},
  buttonContainer: {
    flexBasis: Sizing.cs(400),
    flexShrink: 1,
  },
  button: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  buttonText: {
    marginHorizontal: Sizing.layout.x10,
  },
});
