import React from "react";
import {
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
} from "react-native";
import { Body } from "../theme/components";

export interface ButtonProps extends TouchableOpacityProps {
  title?: string;
  titleStyle?: TextStyle;
}

export const Button = ({
  title,
  disabled,
  children,
  style,
  titleStyle,
  ...rest
}: ButtonProps) => {
  const opacity = disabled ? 0.8 : 1;

  return (
    <TouchableOpacity
      {...rest}
      disabled={disabled}
      style={[
        {
          opacity,
        },
        style,
      ]}
    >
      {title && <Body text={title} style={titleStyle} />}
      {children}
    </TouchableOpacity>
  );
};
