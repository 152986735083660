import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View, ViewProps } from "react-native";
import moment from "moment";
import { LinearGradient } from "expo-linear-gradient";
import { EncryptBar } from "../components/EncryptBar";
import { decrypt, encrypt, useAppSelector } from "../store";
import { Login } from "./LoginScreen";
import { AutoScrollList } from "../components/AutoScrollList";
import { ChatEvent, IncomingType, ChatMessage } from "../api/types";
import { Outlines, Sizing, Typography, useTheme } from "../theme";
import { Body, Highlight, Footnote, SubHeader, Hint, Card, Chip, Content } from "../theme/components";
import { Spacer } from "../components/Spacer";
import bigInt, { BigInteger } from "big-integer";
import { IconName, Icons } from "../theme/icons";
import { useDeviceOrientation } from "@react-native-community/hooks";
import { showMessage } from "react-native-flash-message";
import { Button } from "../components/Button";
import { usePickPartner, useSendMix } from "../hooks/chatHooks";
import { IconButton } from "../components/IconButton";
import { useOpenCrypto } from "../hooks/cryptoHookx";

const styles = StyleSheet.create({
  chatContainer: {
    flex: 1,
    maxWidth: Sizing.cs(1200),
    paddingTop: 0,
  },
  controlsContainer: {
    flexWrap: "wrap",
    alignSelf: "center",
    alignContent: "center"
  },
  cryptoField: {
    borderRadius: Outlines.borderRadius.small,
    marginBottom: Sizing.layout.x20,
    marginHorizontal: Sizing.layout.x10
  },
  notification: {
    alignSelf: "center",
    textAlign: "center",
    borderRadius: Outlines.borderRadius.small,
    padding: Sizing.layout.x20,
    paddingHorizontal: Sizing.layout.x30,
    margin: Sizing.layout.x10,
  },
});

export const ChatScreen = () => {
  const status = useAppSelector((state) => state.chat.status);

  // Simply throw the user back on the login screen if they are not connected
  return (
    <Content >
      {status === "connected" ? <Chat /> : <Login />}
    </Content>
  );
};

const Chat = () => {
  const messages = useAppSelector((state) => state.chat.feed);
  const isLandscape = useDeviceOrientation().landscape;

  return (
    <View style={{ height: "100%", flexDirection: isLandscape ? "row" : "column" }}>
      <CryptoControls />
      {isLandscape && <Spacer size='medium' />}
      <Card style={styles.chatContainer}>
        <AutoScrollList
          data={messages}
          keyExtractor={(item: ChatEvent) => item.payload.id}
          renderItem={({ item, index }) => {
            const prev = index > 0 ? messages[index - 1] : undefined;
            return <ChatEntry event={item} prev={prev} />;
          }}
          style={{ height: 0 }}
        />
        <Spacer size="tiny" />
        <EncryptBar />
      </Card>
    </View>
  );
};

export interface CryptoFieldProps {
  text: string;
  alt: string;
  icon?: IconName,
  altIcon?: IconName;
  onPress?: () => void;
  onPressAlt?: () => void;
  value?: BigInteger;
}

export const CryptoField = (props: CryptoFieldProps) => {
  const { colors } = useTheme();
  const isLandscape = useDeviceOrientation().landscape;
  const onPress = props.value ? props.onPress : props.onPressAlt

  return (
    <Chip style={[{ backgroundColor: colors.background.s200, width: isLandscape ? Sizing.cs(300) : undefined, }, styles.cryptoField]}>
      <TouchableOpacity disabled={!onPress} onPress={onPress} style={{
        padding: Sizing.layout.x15,
        flexDirection: "row",
        alignItems: "center",
      }}>
        {props.value
          ? <>
            <Body><Highlight>{props.text}</Highlight>{": " + props.value}</Body>
            <Spacer size='small' />
            {props.icon && Icons[props.icon]({})}
          </>
          : <Hint>{props.alt}</Hint>
        }
      </TouchableOpacity>
    </Chip>
  )
}

export const CryptoControls = () => {
  const crypto = useAppSelector((state) => state.crypto);
  const isLandscape = useDeviceOrientation().landscape;
  const openCrypto = useOpenCrypto();
  const send = useSendMix();

  return (
    <View>
      <SubHeader text="Cryptosteuerung" />
      <Spacer size="medium" />
      <View style={[{ flexDirection: isLandscape ? "column" : "row" }, styles.controlsContainer]}>
        <CryptoField text="Modulus" alt="Setze Modulus in Crypto" value={crypto.mod} onPressAlt={openCrypto} />
        <CryptoField text="Generator" alt="Setze Generator in Crypto" value={crypto.generator} onPressAlt={openCrypto} />
        <CryptoField text="Dein Mix" alt="Berechne Mix in Crypto" value={crypto.mix} icon="Send" onPress={send} onPressAlt={openCrypto} />
        <CryptoField text="Partner Mix " alt="Wähle Partner Mix im Chat" value={crypto.partner} />
        <CryptoField text="Schlüssel " alt="Berechne Schlüssel mit Mix und Partner" value={crypto.key} />
      </View>
    </View>
  )
}

export interface ChatMessageBubbleProps {
  message: ChatMessage;
  prev?: ChatMessage;
  cryptIcon?: IconName;
  encrypted?: boolean;
  decrypted?: boolean;
}

export const CryptoMessageBubble = ({ message, prev }: ChatMessageBubbleProps) => {
  const partner = useAppSelector((state) => state.chat.partnerName);
  const key = useAppSelector((state) => state.crypto.key);

  const [isDecrypted, setIsDecrypted] = useState(false);
  const [content, setContent] = useState(message);

  function handle() {
    if (key) {
      const crypt = isDecrypted ? encrypt : decrypt;
      setContent({
        ...message,
        body: crypt(content.body, key),
      })
      setIsDecrypted(!isDecrypted)
    } else {
      showMessage({
        message: "Du hat noch keinen Schlüssel berechnet!",
        textStyle: Typography.text.body,
        titleStyle: Typography.text.highlight,
        icon: "auto",
        type: "danger",
        duration: 2500,
      })
    }
  }

  // Assuming the message was encrypted with our mix
  if (message.author == partner) {
    const icon = isDecrypted ? "Decrypt" : "Encrypt";

    return <Button onPress={handle}>
      <ChatMessageBubble message={content} prev={prev} cryptIcon={icon} encrypted={!isDecrypted} decrypted={isDecrypted} />
    </Button>

  } else {
    return <ChatMessageBubble message={message} prev={prev} />
  }
}

export const ChatMessageBubble = ({ message, prev, cryptIcon: bottomIcon, encrypted, decrypted, ...rest }: ChatMessageBubbleProps) => {
  const { colors, baseOnContrast } = useTheme();

  // Determine the coloring and corners of messages based on the user
  const user = useAppSelector((state) => state.chat.user);
  const self = user == message.author;

  let gradient = self
    ? [colors.primary.s200, colors.secondary.s200, colors.primary.s200]
    : [colors.background.s300, colors.background.s300];
  gradient = decrypted ? [colors.accent.s200, colors.accent.s200, colors.accent.s200] : gradient;
  gradient = encrypted ? [colors.secondary.s200, colors.secondary.s200, colors.secondary.s200] : gradient;

  const backgroundColor = gradient[0];
  const textColor = baseOnContrast(backgroundColor);

  const borderRadius = Outlines.borderRadius.small;
  const time_stamp = moment(message.received_at);

  // The chain stops if someone else writes or more than 5 minutes have passed
  const chain = prev && message.author == prev.author && time_stamp.diff(prev.received_at) < 300000;
  const icon = bottomIcon ? Icons[bottomIcon]({ size: "medium", color: textColor }) : null;
  return (
    <View style={{ flex: 1, flexDirection: self ? "row-reverse" : "row", ...rest }}>
      <LinearGradient
        start={[0, 0]}
        end={[1, 1]}
        colors={gradient}
        style={{
          maxWidth: "90%",
          // Set corner piece on the right side and the first message of a chain
          borderRadius,
          borderTopRightRadius: self && !chain ? 0 : borderRadius,
          borderTopLeftRadius: !self && !chain ? 0 : borderRadius,
          padding: Sizing.layout.x15,
          // Reduce spacing in chains
          marginTop: chain ? Sizing.layout.x5 : Sizing.layout.x10,
        }}
      >
        {chain ? undefined : (
          <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
            <Highlight text={message.author} color={textColor} />
            <Spacer size="small" />
            <View style={{ flex: 1 }}></View>
            <Footnote text={time_stamp.format("HH:mm")} color={textColor} />
            <Spacer size="small" />
            {icon}
          </View>
        )}
        <Body color={textColor}>{message.body}<Spacer size="small" />{chain && icon}</Body>
      </LinearGradient>

    </View>
  );
};

export interface ChatEntryProps {
  event: ChatEvent;
  prev?: ChatEvent;
}

// Handle the rendering for different kinds of chat events.
export const ChatEntry = ({ event, prev }: ChatEntryProps) => {
  // Decide if the message can be interpreted as public key or not
  const isPublicMix = (message: ChatMessage) => {
    return /^\d{1,12}$/.test(message.body)
  }

  switch (event.type) {
    case IncomingType.ChatMessage: {
      const message = event.payload;

      if (isPublicMix(message)) {
        return <ChatMix message={message} />;
      } else {
        // Required to pass information about the previous message to
        // get effects like chaining
        const prev_message = prev && prev.type == IncomingType.ChatMessage && !isPublicMix(prev.payload) ? prev.payload : undefined;
        return <CryptoMessageBubble message={message} prev={prev_message} />;
      }
    }
    case IncomingType.UserConnected:
      return (
        <ChatNotification
          text={event.payload.user + " ist dem Chat beigetreten"}
        />
      );
    case IncomingType.UserDisconnected:
      return (
        <ChatNotification
          text={event.payload.user + " hat den Chat verlassen"}
        />
      );
    default:
      return <></>;
  }
};

export interface ChatNotificationProps extends ViewProps {
  text?: string;
}

export const ChatNotification = ({ text, children }: ChatNotificationProps) => {
  return (
    <Chip style={styles.notification}>
      {text ? <Body text={text} /> : null}
      {children}
    </Chip>
  );
};

export interface ChatMixProps {
  message: ChatMessage;
}

export const ChatMix = ({ message }: ChatMixProps) => {
  const pickPartner = usePickPartner();

  const onPress = () => {
    pickPartner(message.author, bigInt(message.body))
  }

  return (
    <ChatNotification
      text={"'" + message.author + "' hat einen Mix geteilt: \n" + message.body}
    >
      <IconButton icon="PickMix" onPress={onPress} gradient />
    </ChatNotification>
  );
};
