
export type Color = string

export type Shades = Record<
    | "s300"
    | "s200"
    | "s100"
    , Color>

export type FullShades = Record<
    | "s800"
    | "s700"
    | "s600"
    | "s500"
    | "s400"
    | "s300"
    | "s200"
    | "s100"
    , Color>

export const grass: FullShades = {
    s800: "#498b89",
    s700: "#5ca891",
    s600: "#7ab691",
    s500: "#8fcc98",
    s400: "#abe19d",
    s300: "#d0f1b0",
    s200: "#f0f8b7",
    s100: "#ffffd8",
}

export const sky: FullShades = {
    s800: "#80b6ff",
    s700: "#87cdff",
    s600: "#a3d8ff",
    s500: "#b7e8ff",
    s400: "#cbf0ff",
    s300: "#daf7ff",
    s200: "#ebfcff",
    s100: "#fffff1",
}

export const dawn: FullShades = {
    s800: "#8e3e5e",
    s700: "#a84257",
    s600: "#be5160",
    s500: "#d6646d",
    s400: "#ef776f",
    s300: "#fd8779",
    s200: "#ffa38f",
    s100: "#ffc198",
}

export type ThemeMode = "light" | "dark"

export interface ColorTheme {
    mode: ThemeMode,
    primary: Shades,
    secondary: Shades,
    accent: Shades,
    foreground: Shades,
    background: Shades,
    error: Color,
    warning: Color,
    success: Color,
    lightText: Color,
    darkText: Color,
}

export const lightTheme: ColorTheme = {
    mode: "light",
    primary: {
        s300: "#3746CC",
        s200: "#4658FF",
        s100: "#6A79FF",
    },
    secondary: {
        s300: "#3EB3E6",
        s200: "#46C8FF",
        s100: "#6AD2FF",
    },
    accent: {
        s300: "#16A779",
        s200: "#1cd097",
        s100: "#42DAAA",
    },
    foreground: {
        s300: "#0C0E14",
        s200: "#70708a",
        s100: "#A4AEB9",
    },
    background: {
        s300: "#F0F2F2",
        s200: "#FFFFFF",
        s100: "#F3F5F5",
    },
    lightText: "#FFFFFF",
    darkText: "#0C0E14",
    error: "#ff718c",
    warning: "#f0dd75",
    success: "#00c27b",
}

export const darkTheme: ColorTheme = {
    mode: "dark",
    primary: {
        s300: "#621A7C",
        s200: "#7a209c",
        s100: "#9241AF",
    },
    secondary: {
        s300: "#A11A7D",
        s200: "#c9209c",
        s100: "#D446AE",
    },
    accent: {
        s300: "#C8C85A",
        s200: "#f9f871",
        s100: "#FBFB8D",
    },
    foreground: {
        s300: "#F2F3F6",
        s200: "#C3C7D1",
        s100: "#9C9FA7",
    },
    background: {
        s300: "#191D25",
        s200: "#242936",
        s100: "#2D3143",
    },
    lightText: "#F2F3F6",
    darkText: "#191D25",
    error: "#d90429",
    warning: "#f0dd75",
    success: "#00FFBF",
}

export const applyOpacity = (hexColor: string, opacity: number): string => {
    const red = parseInt(hexColor.slice(1, 3), 16)
    const green = parseInt(hexColor.slice(3, 5), 16)
    const blue = parseInt(hexColor.slice(5, 7), 16)

    return `rgba(${red}, ${green}, ${blue}, ${opacity})`
}

export const shadeColor = (hexColor: string, percent: number): string => {
    const redGamut: number = parseInt(hexColor.slice(1, 3), 16)
    const greenGamut: number = parseInt(hexColor.slice(3, 5), 16)
    const blueGamut: number = parseInt(hexColor.slice(5, 7), 16)

    const rgb: Array<number> = [redGamut, greenGamut, blueGamut]

    const toShadedGamut = (gamut: number): number => {
        return Math.floor(Math.min(gamut * (1 + percent / 100), 255))
    }

    const toHex = (gamut: number): string => {
        return gamut.toString(16).length === 1
            ? `0${gamut.toString(16)}`
            : gamut.toString(16)
    }

    const shadedRGB: Array<number> = rgb.map(toShadedGamut)
    const shadedHex: Array<string> = shadedRGB.map(toHex)

    const hexString: string = shadedHex.join("")

    return `#${hexString}`
}
