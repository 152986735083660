import React from "react";
import { store } from "./src/store";
import { Provider as ReduxProvider } from "react-redux";
import Navigation from "./src/navigation";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { ThemeProvider } from "./src/theme";
import { Background } from "./src/theme/components";
import FlashMessage from "react-native-flash-message";
import { useDimensions } from "@react-native-community/hooks";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

export default () => {
  let { height } = useDimensions().window;
  return (
    <ReduxProvider store={store}>
      <ThemeProvider>
        <Background>
          <SafeAreaProvider>
            <KeyboardAwareScrollView
              contentContainerStyle={{ height, width: "100%" }}
            >
              <Navigation />
              <FlashMessage position="center" />
            </KeyboardAwareScrollView>
          </SafeAreaProvider>
        </Background>
      </ThemeProvider>
    </ReduxProvider>
  );
};
