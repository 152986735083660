import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";
import { InputBar } from "../components/InputBar";
import { useAppDispatch, useAppSelector, chatActions } from "../store";
import { Sizing, useTheme } from "../theme";
import { Card } from "../theme/components";
import { GradientButton } from "../components/GradientButton";
import { Body } from "../theme/components";

const styles = StyleSheet.create({
  card: {
    alignSelf: "center",
    padding: Sizing.layout.x30,
  },
  heading: {},
  input: {
    width: Sizing.cs(500),
  },
  button: {
    alignSelf: "center",
    height: Sizing.cs(84),
    width: Sizing.cs(300),
  },
  spacer: {
    width: Sizing.layout.x30,
    height: Sizing.layout.x30,
  },
  error: {
    alignSelf: "center",
    textAlign: "center",
    width: Sizing.cs(500),
  },
});

export const Login = () => {
  const { colors } = useTheme();
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.chat.status);
  const error = useAppSelector((state) => state.chat.error);
  const name = useAppSelector((state) => state.chat.name);

  const connect = () => {
    if (name) {
      dispatch(chatActions.connect());
    }
  };
  const setName = (name: string) => {
    dispatch(chatActions.setName(name));
  };

  return (
    <Card style={styles.card}>
      <Body style={styles.heading} text="Gebe deinen Namen zum Chatten ein:" />
      <View style={styles.spacer} />
      <InputBar
        style={styles.input}
        placeholder="Dein Name"
        value={name}
        onChange={(e) => setName(e.nativeEvent.text)}
        onSubmitEditing={connect}
      />
      <View style={styles.spacer} />
      {error ? (
        <Body style={styles.error} color={colors.error} text={error} />
      ) : null}
      <View style={styles.spacer} />
      <GradientButton
        style={styles.button}
        outlined
        onPress={connect}
        disabled={status === "establishing"}
      >
        {status === "establishing" ? (
          <ActivityIndicator color={colors.primary.s200} />
        ) : (
          <Body>Verbinden</Body>
        )}
      </GradientButton>
    </Card>
  );
};
