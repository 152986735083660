import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { useTheme, Sizing, useIsDarkMode } from "../theme";
import {
  Body,
  Header,
  Link,
  SubHeader,
  Card,
  Content,
} from "../theme/components";
import { GradientButton } from "../components/GradientButton";
import { useSideMenuNavigation } from "../navigation/types";

const styles = StyleSheet.create({
  card: {
    padding: Sizing.layout.x50,
    maxWidth: Sizing.cs(900),
    alignSelf: "center",
  },
  logoContainer: {
    height: Sizing.layout.x80,
    flexDirection: "row",
  },
  logoSparkasse: { width: "70%", height: "100%" },
  logoUniBonn: { width: "30%", height: "100%" },
  flexContainer: {
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  contentContainer: {
    marginVertical: Sizing.layout.x30,
  },
  contentButton: {
    margin: Sizing.layout.x10,
    width: Sizing.cs(250),
    height: Sizing.cs(90),
  },
  contentButtonTitle: {
    margin: Sizing.layout.x20,
    textAlign: "center",
  },
  contactContainer: {
    margin: Sizing.layout.x10,
    width: Sizing.cs(360),
  },
});

export const AboutScreen = () => {
  const isDark = useIsDarkMode();
  const nav = useSideMenuNavigation();

  return (
    <Content>
      <Card style={styles.card}>
        <View style={styles.logoContainer}>
          <Image
            style={styles.logoSparkasse}
            resizeMode="contain"
            source={
              isDark
                ? require("../../assets/StiftungWissenLight.png")
                : require("../../assets/StiftungWissenDark.png")
            }
          />
          <Image
            style={styles.logoUniBonn}
            resizeMode="contain"
            source={require("../../assets/UniBonn.png")}
          />
        </View>
        <View style={styles.contentContainer}>
          <Body>
            <Body text="Ein Projekt der " />
            <Link
              text="Stiftung Wissen der Sparkasse KölnBonn"
              url="https://stiftung-wissen-koelnbonn.de/"
            />
            <Body text=" und der " />
            <Link
              text="Rheinischen Friedrich-Wilhelms-Universität Bonn"
              url="https://www.uni-bonn.de"
            />
            <Body text="." />
          </Body>
        </View>
        <View style={styles.contentContainer}>
          <Header text="Inhalt" />
          <View style={styles.flexContainer}>
            <GradientButton
              title="Diffie-Hellman Workshop"
              outlined
              style={styles.contentButton}
              titleStyle={styles.contentButtonTitle}
              onPress={() => nav.navigate("KeyExchange")}
            />
            <GradientButton
              title="MINT-Ferienprogramm"
              outlined
              style={styles.contentButton}
              titleStyle={styles.contentButtonTitle}
              onPress={() => nav.navigate("PwCracking1")}
            />
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.flexContainer}>
            <View style={styles.contactContainer}>
              <SubHeader text="Webseitenverantwortlicher" />
              <Body>
                <Body text="Name:  " />
                <Link
                  text="Dr. Felix Boes"
                  url="https://net.cs.uni-bonn.de/wg/itsec/staff/felix-boes/"
                />
              </Body>
              <Body text="E-Mail: boes@cs.uni-bonn.de" />
            </View>
            <View style={styles.contactContainer}>
              <SubHeader text="Programmierung" />
              <Body text="Name:  Fabian Odenthal" />
              <Body text="E-Mail: fabian.odenthal@uni-bonn.de" />
            </View>
          </View>
        </View>
      </Card>
    </Content>
  );
};
