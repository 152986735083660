import { MaterialTopTabNavigationProp } from "@react-navigation/material-top-tabs";
import {DrawerNavigationProp } from "@react-navigation/drawer"
import { useNavigation } from "@react-navigation/native";

// Type the route within the tab navigator
export type RootTabParamList = {
    MixScreen: undefined;
    CryptoScreen: undefined;
    ChatScreen: undefined;
};

// Alias hook to get typed navigation
export const useTabNavigation = () => useNavigation<MaterialTopTabNavigationProp<RootTabParamList, "CryptoScreen">>();

export type SideMenuParamList = {
    KeyExchange: undefined;
    PwCracking1: undefined;
    StrengthEvaluator: undefined;
    PwCracking2: undefined;
    About: undefined;
}

export const useSideMenuNavigation = () => useNavigation<DrawerNavigationProp<SideMenuParamList,"About">>()