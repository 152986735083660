import React from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import { BigIntInput } from "../components/BigIntInput";
import { EncryptBar } from "../components/EncryptBar";
import { DecryptBar } from "../components/DecryptBar";
import { Sizing } from "../theme";
import { Icons } from "../theme/icons";
import { useAppDispatch, useAppSelector, cryptoActions } from "../store";
import { IconButton, IconButtonProps } from "../components/IconButton";
import { TextProps, Body, SubHeader, Highlight, Card, Chip, Content, FlexContent, } from "../theme/components";
import { Spacer } from "../components/Spacer";
import { useCanPickPartner, useOpenChat, useSendMix } from "../hooks/chatHooks";

export const styles = StyleSheet.create({
  card: {
    minWidth: Sizing.cs(600),
    maxWidth: Sizing.cs(750),
    flex: 1,
    margin: Sizing.layout.x15,
  },
  inputBar: {
    paddingHorizontal: Sizing.layout.x30,
  },
  instructionRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  instructionText: {
    marginHorizontal: Sizing.layout.x30,
    marginVertical: Sizing.layout.x10,
  },
});

const InstructionText = ({ style, ...props }: TextProps) => (
  <Body {...props} style={[styles.instructionText, style]} />
);

export const CryptoScreen = () => {

  return (
    <ScrollView>
      <Content>
        <FlexContent>
          <SecretsCard />
          <EncryptCard />
        </FlexContent>
      </Content>
    </ScrollView>
  );
};

export const SecretsCard = () => {
  return (
    <Card style={styles.card}>
      <SubHeader text="Geheimnisvereinbarung" />
      <Spacer size="medium" />
      <InstructionText text="1. Wähle den Modulus für die Rechenoperationen" />
      <Chip style={styles.inputBar}>
        <Body text="Modulus: " />
        <InputMod />
      </Chip>

      <Spacer size="medium" />
      <InstructionText text="2. Berechne: Generator ^ Dein Geheimnis" />
      <Chip style={styles.inputBar}>
        <Body text="Dein Mix: " />
        <InputGenerator />
        <Body text="^" />
        <InputSecret />
        <Body text="=" />
        <OutputMix />
      </Chip>

      <Spacer size="medium" />
      <InstructionText text="3. Berechne: Partner Mix ^ Dein Geheimnis" />
      <Chip style={styles.inputBar}>
        <Body text="Geteilt: " />
        <InputPartner />
        <Body text="^" />
        <InputSecret />
        <Body text="=" />
        <OutputKey />
      </Chip>
    </Card>
  );
};

const EncryptCard = () => {
  return (
    <Card style={styles.card}>
      <SubHeader text="Ver- und Entschlüsseln" />
      <Spacer size="medium" />
      <InstructionText>
        {"4. Verschlüssle "}
        <Icons.Encrypt />
        {" und Sende "}
        <Icons.Send />
        {" eine Nachricht"}
      </InstructionText>
      <EncryptBar />

      <Spacer size="medium" />
      <InstructionText>
        {"5. Wähle "}
        <Icons.PickMsg />
        {" und Entschlüssle "}
        <Icons.Decrypt />
        {" eine Nachricht"}
      </InstructionText>
      <DecryptBar />

      <Spacer size="medium" />
      <View style={styles.instructionRow}>
        <InstructionText style={{ flex: 1 }}>
          <Highlight text="Optional: " />
          {"Sende "}
          <Icons.Send />
          {" deinen Mix im Chat: "}
        </InstructionText>
        <ButtonSendMix />
      </View>

      <Spacer size="medium" />
      <View style={styles.instructionRow}>
        <InstructionText style={{ flex: 1 }}>
          <Highlight text="Optional: " />
          {"Wähle einen "}
          <Icons.PickMix />
          {" Partner Mix im Chat: "}
        </InstructionText>
        <ButtonPickMix />
      </View>
    </Card>
  );
};


export const InputMod = () => {
  const dispatch = useAppDispatch();
  const mod = useAppSelector((state) => state.crypto.mod);
  return (
    <BigIntInput
      placeholder="Hier Modulus eingeben"
      setInt={(val) => dispatch(cryptoActions.setMod(val))}
      intValue={mod}
    />
  );
};

export const InputGenerator = () => {
  const dispatch = useAppDispatch();
  const generator = useAppSelector((state) => state.crypto.generator);
  return (
    <BigIntInput
      placeholder="Generator"
      setInt={(val) => dispatch(cryptoActions.setGenerator(val))}
      intValue={generator}
    />
  );
};

export const InputSecret = () => {
  const dispatch = useAppDispatch();
  const secret = useAppSelector((state) => state.crypto.secret);
  return (
    <BigIntInput
      placeholder="Geheimnis"
      setInt={(val) => dispatch(cryptoActions.setSecret(val))}
      intValue={secret}
    />
  );
};

export const InputPartner = () => {
  const dispatch = useAppDispatch();
  const partner = useAppSelector((state) => state.crypto.partner);
  return (
    <BigIntInput
      placeholder="Partner"
      setInt={(val) => dispatch(cryptoActions.setPartner(val))}
      intValue={partner}
    />
  );
};

export const OutputMix = () => {
  const mix = useAppSelector((state) => state.crypto.mix);
  return <BigIntInput placeholder="Mix" intValue={mix} />;
};

export const OutputKey = () => {
  const key = useAppSelector((state) => state.crypto.key);
  return <BigIntInput placeholder="Geteilt" intValue={key} />;
};

export const ButtonSendMix = ({ ...props }: IconButtonProps) => {
  const sendMix = useSendMix();

  return <IconButton onPress={sendMix} gradient icon="Send" />;
};

export const ButtonPickMix = () => {
  const openChat = useOpenChat();
  const canPick = useCanPickPartner();

  const onPress = () => {
    if (canPick()) {
      openChat();
    }
  };
  return <IconButton gradient onPress={onPress} icon="PickMix" />;
};
