import { TextStyle } from "react-native";
import { useTheme } from "./ThemeProvider";
import React, { ReactElement } from "react";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { fontSize, lineHeight, } from "./typography";


export type IconSize = "tiny" | "small" | "medium" | "large" | "huge";
export const icons: Record<IconSize, TextStyle> = {
    huge: {
        ...fontSize.x70,
        ...lineHeight.x70,
    },
    large: {
        ...fontSize.x60,
        ...lineHeight.x60,
    },
    medium: {
        ...fontSize.x50,
        ...lineHeight.x50,
    },
    small: {
        ...fontSize.x30,
        ...lineHeight.x30,
    },
    tiny: {
        ...fontSize.x10,
        ...lineHeight.x10,
    },
};

export interface PartialIconProps {
    color?: string;
    style?: TextStyle;
    size?: IconSize;
}
// Note: This is garbage

export interface MaterialIconProps extends PartialIconProps {
    name: keyof typeof MaterialCommunityIcons.glyphMap;
}

export const MaterialIconTemplate = ({
    name, color, style, size
}: MaterialIconProps) => {
    const { colors } = useTheme();
    return (
        <MaterialCommunityIcons
            name={name}
            style={[{ color: color ?? colors.foreground.s300 }, size ? icons[size] : icons.medium, style]} />
    );
};

export interface FeatherIconProps extends PartialIconProps {
    name: keyof typeof Feather.glyphMap;
}

export const FeatherIconTemplate = ({
    name, color, size, style,
}: FeatherIconProps) => {
    const { colors } = useTheme();
    return (
        <Feather
            name={name}
            style={[{ color: color ?? colors.foreground.s300 }, size ? icons[size] : icons.medium, style]} />
    );
};

export interface IconMap {
    [key: string]: (props: PartialIconProps) => ReactElement;
}
export const Icons: IconMap = {
    ChatTab: (props) => <MaterialIconTemplate name="chat" {...props} />,
    CryptoScreen: (props) => <MaterialIconTemplate name="lock" {...props} />,
    MixScreen: (props) => <MaterialIconTemplate name="palette" {...props} />,
    Send: (props) => <MaterialIconTemplate name="send" {...props} />,
    Encrypt: (props) => <MaterialIconTemplate name="lock" {...props} />,
    Decrypt: (props) => <MaterialIconTemplate name="lock-open" {...props} />,
    PickMix: (props) => <MaterialIconTemplate name="account-search" {...props} />,
    PickMsg: (props) => <MaterialIconTemplate name="magnify" {...props} />,
    Menu: (props) => <MaterialIconTemplate name="menu" {...props} />,
    DarkMode: (props) => <FeatherIconTemplate name="moon" {...props} />,
    LightMode: (props) => <FeatherIconTemplate name="sun" {...props} />,
    CheckPwStrength: (props) => (
        <MaterialIconTemplate name="lock-question" {...props} />
    ),
};
export type IconName = keyof IconMap;
