import React from "react";
import { ImageBackground, StyleProp, ViewStyle } from "react-native";
import { Sizing } from "../theme";
import tinycolor from "tinycolor2";
import Slider from "@react-native-community/slider";

export interface ColorPickerProps {
  disabled?: boolean;
  onChange: (color: string) => void;
  style?: StyleProp<ViewStyle>;
}

// TODO: Add saturation and maybe value slider
export const ColorPicker = ({
  disabled = false,
  onChange,
  style,
}: ColorPickerProps) => {
  const opacity = disabled ? 0.4 : 1;
  const update = (hue: number) => {
    // Workaround as disabled property does not work on web.
    // NOTE: Might cause update issues due to impure function.
    if (disabled) return;
    const newColor = tinycolor({
      h: hue,
      v: 1,
      s: 1,
    }).toHexString();
    onChange(newColor);
  };

  return (
    <ImageBackground
      style={[
        style,
        {
          opacity,
        },
      ]}
      source={require("../../assets/color_slider.png")}
      resizeMode="stretch"
      imageStyle={{
        transform: [{ scaleY: 0.25 }],
        marginHorizontal: Sizing.layout.x10,
      }}
    >
      <Slider
        style={{ flex: 1, margin: 0 }}
        disabled={disabled}
        minimumValue={0}
        maximumValue={359}
        step={1}
        maximumTrackTintColor="transparent"
        minimumTrackTintColor="transparent"
        thumbTintColor={"#00000050"}
        onSlidingStart={update}
        onValueChange={update}
        trackImage={require("../../assets/color_slider.png")}
      />
    </ImageBackground>
  );
};
