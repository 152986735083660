import React from "react";
import { InputBar } from "./InputBar";
import {
  useAppDispatch,
  useAppSelector,
  decrypt,
  cryptoActions,
} from "../store";
import { IconButton } from "./IconButton";

export const DecryptBar = () => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector((state) => state.crypto.selected);
  const key = useAppSelector((state) => state.crypto.key);
  const disableDecrypt = !key || !selected;
  const disabledPick = false;

  const handlePick = () => {
    if (!disabledPick) {
    }
  };
  const handleDecrypt = () => {
    if (!disableDecrypt && key) {
      dispatch(cryptoActions.setSelected(decrypt(selected, key)));
    }
  };

  return (
    <InputBar
      placeholder="Nachricht des Partners"
      innerLeft={
        <IconButton
          onPress={handlePick}
          disabled={disabledPick}
          icon="PickMsg"
        />
      }
      innerRight={
        <IconButton
          onPress={handleDecrypt}
          disabled={disableDecrypt}
          icon="Decrypt"
        />
      }
      onChangeText={(text) => dispatch(cryptoActions.setSelected(text))}
      value={selected}
      onSubmitEditing={handleDecrypt}
    />
  );
};
