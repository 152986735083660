import React, { useState } from "react";
import { ScrollView, View } from "react-native";
import { Sizing, Typography } from "../theme";
import { Top1000Passwords } from "../util/top1000passwords";
import { InputBar } from "../components/InputBar";
import { IconButton } from "../components/IconButton";
import { Body, SubHeader, Hint, Card, Content, FlexContent } from "../theme/components";
import { Spacer } from "../components/Spacer";
import { PasswordGuess, usePasswordGuessing } from "../hooks/usePasswordGuessing";
import {
  PasswordGuessEntry,
} from "../components/PasswordGuessEntry";

/**
 * The list of passwords to use for this excercise.
 * 
 * NOTE: Having them all stored is in source code is inefficient but fine for
 * this specific context.
 */
const passwords = Top1000Passwords.slice(0, 1000);

/**
 * Simple hardcoded scoring for the Top1000 password list.
 */
const scoring = (rank: number) => {
  if (rank <= 10) return 1000;
  if (rank <= 20) return 800;
  if (rank <= 50) return 700;
  if (rank <= 100) return 400;
  if (rank <= 200) return 300;
  if (rank <= 500) return 200;
  return 100;
};

export const PwCrackingScreen1 = () => {
  const { totalScore, makeGuess, guessedWords } = usePasswordGuessing(
    passwords,
    10,
    scoring
  );

  return (
    <Content>
      <Card style={{ height: "100%", maxWidth: Sizing.cs(1500), alignSelf: "center" }}>
        <View style={{ alignSelf: "center" }}>
          <Body text="Sammle Punkte durch Erraten der 1000 weltweit meist genutzten
            Passwörter! Was für Muster fallen dir auf?"/>
          <Spacer size="tiny" />
          <Hint text="Hinweis: 4-10 Zeichen, keine Symbole und Groß-/Kleinschreibung ist
            egal."/>
        </View>
        <Spacer size="small" />
        <GuessList guesses={guessedWords} />
        <Spacer size="small" />
        <GuessInput totalScore={totalScore} makeGuess={makeGuess} insensitive />
      </Card>
    </Content>
  );
};

export const GuessList = ({ guesses }: { guesses: PasswordGuess[] }) => {
  return (
    <ScrollView>
      <FlexContent>
        {guesses.map((item) => (
          <PasswordGuessEntry key={item.rank} item={item} />
        ))}
      </FlexContent>
    </ScrollView>
  )
}
export const ScoreDisplay = (props: { score: number }) => {
  return (
    <View style={{ width: Sizing.cs(120), alignItems: "center" }}>
      <Body>Punkte</Body>
      <SubHeader style={{ ...Typography.lineHeight.x50 }}>
        {props.score}
      </SubHeader>
    </View>
  )
}

export interface GuessInputProps {
  totalScore: number,
  makeGuess: (guess: string) => void,
  // Whether the input should be converted to lowercase before submitting or not.
  insensitive?: boolean
}

export const GuessInput = (props: GuessInputProps) => {
  const [input, setInput] = useState("");
  const onSubmit = () => {
    const guess = props.insensitive ? input.toLowerCase() : input;
    props.makeGuess(guess);
    setInput("");
  };

  return (
    <InputBar
      placeholder="Rate ein Passwort"
      outerLeft={<ScoreDisplay score={props.totalScore} />}
      outerRight={<IconButton gradient onPress={onSubmit} icon="PickMsg" />}
      style={{
        width: Sizing.cs(600),
        alignSelf: "center",
        // Position input bar on bottom for larger screens
        alignItems: "flex-end",
      }}
      onChangeText={setInput}
      onSubmitEditing={onSubmit}
      value={input} />
  );
}

