import React, { createContext, ReactNode, useContext, useState } from "react";
import { ColorSchemeName, useColorScheme } from "react-native";
import { lightTheme, darkTheme, ColorTheme } from "./colors";
import tinycolor from "tinycolor2";
import { generateStyles, ThemedStyles } from "./styles";

export interface Theme {
  colors: ColorTheme;
  toggleTheme: () => void;
  baseOnContrast: (background: string) => string;
  themeStyles: ThemedStyles;
}

export const ThemeContext = createContext<Theme>({
  colors: lightTheme,
  toggleTheme: () => {},
  baseOnContrast: (background) => "",
  themeStyles: generateStyles(lightTheme),
});

// Shorthand to access the color theme within the themecontext
export const useTheme = () => {
  return useContext(ThemeContext);
};

export const useIsDarkMode = () => {
  const { colors } = useTheme();
  return colors.mode == "dark";
};

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  // Start with the deafault system color theme
  const systemMode = useColorScheme();
  // Save what kind of theme is currently in use
  const [mode, setMode] = useState<ColorSchemeName>(systemMode);

  const selectTheme = () => (mode === "dark" ? darkTheme : lightTheme);
  const colors = selectTheme();

  const toggleTheme = () => setMode(mode === "light" ? "dark" : "light");

  // Decide whether light or dark color is better due to contrast
  // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  function baseOnContrast(background: string): string {
    // Assume regular background color if transparent
    return background === "transparent"
      ? baseOnContrast(colors.background.s200)
      : tinycolor(background).getLuminance() < 0.179
      ? colors.lightText
      : colors.darkText;
  }

  // Create the new themed stylesheet
  const themeStyles = generateStyles(colors);

  const newTheme: Theme = {
    colors,
    toggleTheme,
    baseOnContrast,
    themeStyles,
  };

  return (
    <ThemeContext.Provider value={newTheme}>{children}</ThemeContext.Provider>
  );
};
