import React, { useRef } from "react";
import { TextInputProps } from "react-native";
import { TextInput } from "react-native-gesture-handler";
import { Typography, Sizing, useTheme, Outlines } from "../theme";

export interface InputProps extends TextInputProps { }

export const Input = ({ style, ...rest }: InputProps) => {
  const { colors } = useTheme();

  return (
    <TextInput
      style={[
        {
          ...Typography.text.body,
          color: colors.foreground.s300,
          borderRadius: Outlines.borderRadius.full,
          paddingHorizontal: Sizing.layout.x30,
          paddingVertical: Sizing.layout.x10,
        },
        style,
      ]}
      placeholderTextColor={colors.foreground.s200}
      disableFullscreenUI
      maxLength={1000}
      {...rest}
    />
  );
};
