import { CHAT_ENDPOINT, KEEP_ALIVE_INTERVAL as PING_INTERVAL, PROTOCOL_ERROR_CODE } from "./constants";
import { ErrorCode, UserInfo, ChatEvent, KeepAliveEvent, ErrorEvent, IncomingEvent, IncomingType, PostChatMessageEvent } from "./types";

/**
 * Configuration for a `ChatSocket`.
 */
export interface ChatSocketConfig {
    username: string,
    onError(error: ErrorCode): void;
    onChatEvent(msg: ChatEvent): void;
    onConnected(info: UserInfo): void;
}

/**
 * A wrapper around a websocket that accounts for the custom subprotocol functionality.
 */
export class ChatSocket {
    socket: WebSocket;

    /**
     * Connects to the chat server at the defualt URL and logs in with the
     * given `username` in `config`.
     * 
     * @param {ChatSocketConfig} config Provides the username used to connect
     * as well as the abstracted websocket functionality that already accounted
     * for the subprotocol and parsed the data into the correct types.
     */
    constructor(config: ChatSocketConfig) {
        // Timer to periodically send a keep alive message within the default interval.
        let ping_interval: NodeJS.Timer;

        // Make sure the username is safely encoded and initialize the connection.
        const URI = CHAT_ENDPOINT + encodeURI(config.username);
        let socket = new WebSocket(URI);

        socket.onopen = (even) => {
            // Periodically tell the server to keep the connection open
            ping_interval = setInterval(() => {
                socket.send(JSON.stringify(new KeepAliveEvent))
            }, PING_INTERVAL);
        }

        socket.onclose = ({ code, reason }) => {
            // Stop pinging the server
            clearInterval(ping_interval)

            // Check if the close code was a known protocol error
            if (code == PROTOCOL_ERROR_CODE) {
                // If reason is not specified, we assume the old server version
                // were the error was already processed as message
                if (reason) {
                    const error: ErrorEvent = JSON.parse(reason);
                    console.log(error);
                    config.onError(error.payload.code);
                }
            } else {
                // We did not receive a protocol error, so the connection
                // was likely just lost or soemthing unexpected happend.
                config.onError(ErrorCode.ConnectionLost);
            }
        }

        // Errors are already handled by `onclose`, so we just log them here.
        socket.onerror = (e) => console.error("Connection error:", e)

        // Wrap the provided `config.onmessage` by parsing the websocket subprotocol
        // into useable events.
        socket.onmessage = ({ data }) => {
            // Parse the incoming message
            const output: IncomingEvent = JSON.parse(data);
            if (!output) {
                console.error("API Error: Unknown output type");
                return;
            }

            // Handle the incoming even type
            switch (output.type) {
                case IncomingType.Error:
                    // A custom protocol error occured.
                    config.onError(output.payload.code)
                    break
                case IncomingType.Registered:
                    // The connection is ready for chat exchange.
                    config.onConnected(output.payload);
                    break
                default:
                    // Otherwise we have any kind of event.
                    config.onChatEvent(output);
            }
        }

        // To post a message the class has to have a reference to the socket
        this.socket = socket
    }

    /**
     * Parses a string into a proper subprotocol type.
     * 
     * @param {string} msg the string that gets sent in form of a chat message.
     * Its important to note that the server handles things like author ID,
     * time stamp etc.
     */
    postMessage(msg: string) {
        this.socket.send(JSON.stringify(new PostChatMessageEvent(msg)))
    }
}
