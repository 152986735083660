import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import bigInt, { BigInteger } from "big-integer";

export interface CryptoState {
  mod?: BigInteger;
  secret?: BigInteger;
  generator?: BigInteger;
  partner?: BigInteger;
  mix?: BigInteger;
  key?: BigInteger;
  selected: string;
};

export const initialCryptoState: CryptoState = {
  selected: "",
};

const update = (state: CryptoState) => {
  state.mix = state.mod && state.secret && state.generator
    ? state.generator.modPow(state.secret, state.mod)
    : undefined
  state.key = state.mod && state.secret && state.partner
    ? state.partner.modPow(state.secret, state.mod)
    : undefined
}

export const cryptoSlice = createSlice({
  name: "crypto",
  initialState: initialCryptoState,
  reducers: {
    setMod: (state, action: PayloadAction<BigInteger | undefined>) => {
      state.mod = action.payload;
      update(state);
    },
    setGenerator: (state, action: PayloadAction<BigInteger | undefined>) => {
      state.generator = action.payload;
      update(state);
    },
    setSecret: (state, action: PayloadAction<BigInteger | undefined>) => {
      state.secret = action.payload;
      update(state);
    },
    setPartner: (state, action: PayloadAction<BigInteger | undefined>) => {
      state.partner = action.payload;
      update(state);
    },
    setSelected: (state, action: PayloadAction<string>) => {
      state.selected = action.payload;
    }
  },
});

export const encrypt = (message: string, key: BigInteger) => {
  return caesarRot(nurAscii(message), key, 1);
}

export const decrypt = (message: string, key: BigInteger) => {
  return caesarRot(nurAscii(message), key, -1);
}

export const cryptoActions = cryptoSlice.actions;
export default cryptoSlice.reducer;

// TODO: Refactor
// Funktionen zur Krypto Berechnung
export const nurAscii = (nachricht: string) => {
  nachricht = nachricht.replace(/[ä]/g, "ae");
  nachricht = nachricht.replace(/[ü]/g, "ue");
  nachricht = nachricht.replace(/[ö]/g, "oe");
  nachricht = nachricht.replace(/[Ä]/g, "Ae");
  nachricht = nachricht.replace(/[Ü]/g, "Ue");
  nachricht = nachricht.replace(/[Ö]/g, "Oe");
  nachricht = nachricht.replace(/[ß]/g, "ss");
  // Druckbare Asciizeichen von " " bis "~".

  return nachricht;
}

export const generateKey = (key: bigInt.BigInteger) => {
  // Linearer Kongruenzgenerator

  // Modulus, Faktor und Inkrement frei waehlbar.
  // So gewaehlt, dass es zur Zeichenrange passt und
  // alle der Zahlen 0 bis modulus-1 im Schluesselstrom auftauchen.
  // Startwert ist das gemeinsame Geheimnis.
  const modulus = 50;
  const faktor = 21;
  const inkrement = 9;

  return key?.times(faktor).plus(inkrement)?.mod(modulus);
}

// Bekommt einen String und einen Schluesselstartwert
// Richtung ist 1 zum Ver- und -1 zum Entschluesseln
export const caesarRot = (
  text: string,
  key: bigInt.BigInteger,
  richtung: number
) => {
  // In Zahlen umwandeln
  // Funktioniert so vermutlich zwischendurch mit dem Zuweisen nicht
  // let UnicodeWerte = text.charCodeAt(0);

  // String in einen Array von Strings mit je nur einem Zeichen auftrennen
  // Vereinfacht spätere Bearbeitung, da Strings nicht veränderbar
  let einzelZeichen = text.split("");

  let length = text.length;

  let i;
  let tmp;
  let rotiert;

  for (i = 0; i < length; i++) {
    //generiert den naechsten Wert in der Schluesselsequenz
    key = generateKey(key);

    //aktuellen Wert in UnicodeWert umwandeln
    tmp = einzelZeichen[i].charCodeAt(0);

    // Wir interessieren uns nur fuer die Zeichen
    //   0-9  <=>  48 -  57
    //   A-Z  <=>  65 -  90
    //   a-z  <=>  97 - 122
    // Wir rechnen um:
    //   0-9  <=>  0 -  9
    //   A-Z  <=> 10 - 35
    //   a-z  <=> 36 - 61
    if (48 <= tmp && tmp <= 57) {
      tmp = tmp - 48;
    } else if (65 <= tmp && tmp <= 90) {
      tmp = 10 + tmp - 65;
    } else if (97 <= tmp && tmp <= 122) {
      tmp = 10 + 26 + tmp - 97;
    } else {
      // aktuelles Zeichen wird nicht verschluesselt
      continue;
    }

    // aktuellen Wert um aktuelles Schluesselzeichen shiften
    tmp = key.times(richtung).plus(tmp);
    // Das Ergebnis kann negativ sein, wenn schluessel*richtung <0 ist.
    // Also bringen wir tmp wieder in den Bereich 0 <= tmp < 62.
    tmp = tmp.mod(62).plus(62).mod(62).valueOf();

    // Wir interessieren uns nur fuer die Zeichen
    //   0-9  <=>  48 -  57
    //   A-Z  <=>  65 -  90
    //   a-z  <=>  97 - 122
    // Wir hatten umgerechnet:
    //   0-9  <=>  0 -  9
    //   A-Z  <=> 10 - 35
    //   a-z  <=> 36 - 61
    if (0 <= tmp && tmp <= 9) {
      tmp = tmp + 48;
    } else if (10 <= tmp && tmp <= 35) {
      tmp = tmp + 55;
    } else if (36 <= tmp && tmp <= 61) {
      tmp = tmp + 61;
    }
    //aktuelles Zeichen wieder zu einem Char machen
    einzelZeichen[i] = String.fromCharCode(tmp);
  }

  //einzelne zeichen wieder zu einem String zusammen fassen
  rotiert = einzelZeichen.join("");

  //als variable zurueckgeben
  //besser state setzen?
  return rotiert;
}
