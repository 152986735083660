import { ViewStyle, StyleSheet } from "react-native"
import { cs } from "./sizing"

type BorderRadius = "small" | "base" | "large" | "full"
export const borderRadius: Record<BorderRadius, number> = {
  small: cs(20),
  base: cs(40),
  large: cs(60),
  full: 9999,
}

type BorderWidth = "hairline" | "thin" | "base" | "thick"
export const borderWidth: Record<BorderWidth, number> = {
  hairline: StyleSheet.hairlineWidth,
  thin: 2,
  base: 3,
  thick: 7,
}

type Shadow = "outer" | "inner"
export const shadow: Record<Shadow, ViewStyle> = {
  // Use on outer elements to perceive as lifted up
  outer: {
    shadowOffset: {
      width: 3,
      height: 4
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 4,
  },
  // Use on inner elements to simulate a "hole" like effect
  inner: {
    shadowOffset: {
      width: -2,
      height: 2
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 4,
  },
}
