import { StyleSheet } from "react-native";
import * as Sizing from "./sizing";
import * as Outlines from "./outlines";
import * as Typography from "./typography";
import { ColorTheme } from "./colors";

export const layout = StyleSheet.create({
    flexContent: {
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "center",
    },
    contentContainer: {
        width: "100%",
        height: "100%",
        alignSelf: "center",
        justifyContent: "center",
        padding: Sizing.layout.x30,
        maxWidth: Sizing.cs(1600)
    },
})

export const generateStyles = (colors: ColorTheme) => StyleSheet.create({
    background: {
        width: "100%",
        height: "100%",
        backgroundColor: colors.background.s300,
    },
    card: {
        borderRadius: Outlines.borderRadius.base,
        padding: Sizing.layout.x30,
        backgroundColor: colors.background.s200,
        shadowColor: colors.primary.s200,
        ...Outlines.shadow.outer,
    },
    chip: {
        alignItems: "center",
        flexDirection: "row",
        borderRadius: Outlines.borderRadius.full,
        backgroundColor: colors.background.s300,
        shadowColor: colors.primary.s200,
        ...Outlines.shadow.inner,
    },
    valueField: {
        alignItems: "center",
        flexDirection: "row",
        borderRadius: Outlines.borderRadius.small,
        backgroundColor: colors.background.s300,
        shadowColor: colors.primary.s200,
        ...Outlines.shadow.inner,
    },
    inputField: {
        color: colors.foreground.s300,
        borderRadius: Outlines.borderRadius.full,
        paddingHorizontal: Sizing.layout.x30,
        paddingVertical: Sizing.layout.x10,
        ...Typography.text.body,
    }
})
export type ThemedStyles = ReturnType<typeof generateStyles>;
