import React from "react";
import { View, ViewProps } from "react-native";
import { Sizing } from "../theme";

type SizeType = "tiny" | "small" | "medium" | "large" | number;

export interface SpacerProps extends ViewProps {
  size?: SizeType;
}

function matchSize(size: SizeType): number {
  switch (size) {
    case "tiny":
      return Sizing.layout.x5;
    case "small":
      return Sizing.layout.x10;
    case "medium":
      return Sizing.layout.x30;
    case "large":
      return Sizing.layout.x50;
    default:
      return size;
  }
}

export const Spacer = ({ size = "medium", style, ...props }: SpacerProps) => {
  const s = matchSize(size);
  return (
    <View
      style={[
        {
          width: s,
          height: s,
        },
        style,
      ]}
      {...props}
    />
  );
};
