import { TextStyle, Platform } from "react-native";
import { systemWeights } from "react-native-typography";
import { cs } from "./sizing";

export type FontSize = "x10" | "x20" | "x30" | "x40" | "x50" | "x60" | "x70";
type FontWeight = "thin" | "light" | "regular" | "semibold" | "bold";
type LetterSpacing = "x30" | "x40";

export const fontSize: Record<FontSize, TextStyle> = {
  x10: {
    fontSize: cs(11),
  },
  x20: {
    fontSize: cs(12),
  },
  x30: {
    fontSize: cs(14),
  },
  x40: {
    fontSize: cs(17),
  },
  x50: {
    fontSize: cs(21),
  },
  x60: {
    fontSize: cs(26),
  },
  x70: {
    fontSize: cs(32),
  },
};

export const fontWeight: Record<FontWeight, TextStyle> = {
  thin: {
    ...systemWeights.thin,
  },
  light: {
    ...systemWeights.light,
  },
  regular: {
    ...systemWeights.regular,
  },
  semibold: {
    ...systemWeights.semibold,
  },
  bold: {
    ...systemWeights.bold,
  },
};

export const letterSpacing: Record<LetterSpacing, number> = {
  x30: cs(2),
  x40: cs(3),
};

export const lineHeight: Record<FontSize, TextStyle> = {
  x10: {
    lineHeight: cs(12),
  },
  x20: {
    lineHeight: cs(16),
  },
  x30: {
    lineHeight: cs(20),
  },
  x40: {
    lineHeight: cs(24),
  },
  x50: {
    lineHeight: cs(28),
  },
  x60: {
    lineHeight: cs(32),
  },
  x70: {
    lineHeight: cs(44),
  },
};

const monospaceFontFamily = Platform.select({
  ios: "Menlo",
  android: "monospace",
});

type Monospace = "base";
export const monospace: Record<Monospace, TextStyle> = {
  base: {
    fontFamily: monospaceFontFamily,
    letterSpacing: letterSpacing.x30,
  },
};

export const text: Record<
  "header" | "subHeader" | "highlight" | "body" | "hint" | "footnote" | "link",
  TextStyle
> = {
  header: {
    alignSelf: "center",
    ...fontSize.x70,
    ...lineHeight.x70,
    ...fontWeight.bold,
  },
  subHeader: {
    alignSelf: "center",
    ...fontSize.x60,
    ...lineHeight.x60,
    ...fontWeight.semibold,
  },
  highlight: {
    ...fontSize.x50,
    ...lineHeight.x50,
    ...fontWeight.semibold,
  },
  body: {
    ...fontSize.x50,
    ...lineHeight.x50,
    ...fontWeight.regular,
  },
  hint: {
    ...fontSize.x50,
    ...lineHeight.x50,
    ...fontWeight.light,
  },
  footnote: {
    ...fontSize.x40,
    ...lineHeight.x40,
    ...fontWeight.thin,
  },
  link: {
    ...fontSize.x50,
    ...lineHeight.x50,
  },
};
