import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { View, StyleSheet, Image, ViewStyle, ViewProps, ScrollView } from "react-native";
import { ColorPicker } from "../components/ColorPicker";
import { GradientButton } from "../components/GradientButton";
import { useAppDispatch, useAppSelector, mixActions, Target } from "../store";
import { Outlines, Sizing, useTheme } from "../theme";
import { useDeviceOrientation } from "@react-native-community/hooks";
import { Body, SubHeader, Card, Content } from "../theme/components";
import { Spacer } from "../components/Spacer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  playerRow: {
    flexDirection: "row",
  },
  card: {
    padding: Sizing.layout.x10,
  },
  startContent: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  playerImage: {
    height: Sizing.cs(200),
    width: Sizing.cs(200),
  },
  button: {
  },
  field: {
    alignItems: "center",
    justifyContent: "center",
  },
  startButton: {
    width: "50%",
    height: Sizing.layout.x70,
    margin: Sizing.layout.x50,
  }
});

const useItemLayout = (flex: number = 1): ViewStyle => {
  const lockRatio = useDeviceOrientation().portrait;
  return {
    borderRadius: Outlines.borderRadius.full,
    margin: Sizing.layout.x10,
    aspectRatio: lockRatio ? flex : undefined,
    height: lockRatio ? undefined : Sizing.layout.x80,
    flex,
  };
}

export const MixScreen = () => {
  const space = "medium";
  return (
    <ScrollView>
      <Content>
        <Card style={[{ flexDirection: "row" }, styles.card]}>
          <ButtonGenerate />
          <Spacer />
          <FieldGenerator />
          <Spacer />
          <ButtonRestart />
        </Card>
        <Spacer size={space} />
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            <Player target="alice" />
          </View>
          <Spacer />
          <View style={{ flex: 1 }}>
            <Player target="bob" />
          </View>
        </View>
      </Content>
    </ScrollView>
  );
};

export interface TargetProps {
  target: Target;
}

const Player = ({ target }: TargetProps) => {
  const hasStarted = useAppSelector((state) => state.mix[target].hasStarted);
  return hasStarted ? <PlayerControls target={target} /> : <PlayerStart target={target} />;
};

const PlayerStart = ({ target }: TargetProps) => {
  const dispatch = useAppDispatch();
  const name = target === "bob" ? "Bob" : "Alice";
  const image =
    target === "bob"
      ? require("../../assets/bob.png")
      : require("../../assets/alice.png");

  return (
    <Card style={[styles.card, styles.startContent]} >
      <Spacer />
      <SubHeader text={name} />
      <Spacer />
      <Image
        style={styles.playerImage}
        source={image}
        resizeMode="contain"
      />
      <GradientButton
        outlined
        title={"Start"}
        style={styles.startButton}
        onPress={() => {
          dispatch(mixActions.start(target));
        }}
      />
    </Card>
  );
};

const PlayerControls = ({ target }: TargetProps) => {
  const Row = (props: ViewProps) => <View
    style={[
      {
        flexDirection: target === "alice" ? "row" : "row-reverse",
      },
    ]}
    {...props}
  />;

  return (
    <Card style={styles.card}>
      <Row>
        <Picker target={target} />
        <FieldSecret target={target} />
      </Row>
      <Row>
        <ButtonMix target={target} />
        <FieldMixed target={target} />
      </Row>
      <Row>
        <ButtonSend target={target} />
        <FieldReceived target={target} />
      </Row>
      <Row>
        <ButtonFinish target={target} />
        <FieldResult target={target} />
      </Row>
    </Card>
  )
};

/**
 * What follows is each individual control button or field.
 * Each component only selects what's necessary from the redux store and hence
 * only rerenders if these variables change.
 *
 * Buttons and fields then delegate the desired settings further to a more generic
 * component for better code reuse.
 */

export const ButtonGenerate = () => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    (state) => state.mix.alice.hasMixed || state.mix.bob.hasMixed
  );
  const item = useItemLayout();

  return (
    <GradientButton
      style={[item, styles.button]}
      outlined
      title={"Generator"}
      disabled={disabled}
      onPress={() => dispatch(mixActions.generate())}
    />
  );
};

export const ButtonRestart = () => {
  const dispatch = useAppDispatch();
  const item = useItemLayout();

  return (
    <GradientButton
      style={[item, styles.button]}
      outlined
      title={"Neustart"}
      onPress={() => {
        dispatch(mixActions.reset());
        dispatch(mixActions.generate());
      }}
    />
  );
};

export const Picker = ({ target }: TargetProps) => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector((state) => state.mix[target].hasMixed);
  const item = useItemLayout();

  return (
    <ColorPicker
      style={[item, styles.button]}
      disabled={disabled}
      onChange={(color: string) => dispatch(mixActions.pick({ target, color }))}
    />
  );
};

export const ButtonMix = ({ target }: TargetProps) => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    (state) => !state.mix[target].hasPicked || state.mix[target].hasMixed
  );
  const item = useItemLayout();

  return (
    <GradientButton
      style={[item, styles.button]}
      outlined
      title={"Mischen"}
      disabled={disabled}
      onPress={() => {
        dispatch(mixActions.mix(target));
      }}
    />
  );
};

export const ButtonSend = ({ target }: TargetProps) => {
  const other = target === "bob" ? "alice" : "bob";
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    (state) =>
      !state.mix[target].hasMixed ||
      state.mix[target].hasSent ||
      !state.mix[other].hasStarted
  );
  const item = useItemLayout();

  return (
    <GradientButton
      style={[item, styles.button]}
      outlined
      title={"Senden"}
      disabled={disabled}
      onPress={() => {
        dispatch(mixActions.send(target));
      }}
    />
  );
};

export const ButtonFinish = ({ target }: TargetProps) => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(
    (state) =>
      !state.mix[target].hasPicked ||
      !state.mix[target].hasReceived ||
      state.mix[target].hasResult
  );
  const item = useItemLayout();

  return (
    <GradientButton
      style={[item, styles.button]}
      outlined
      title={"Mischen"}
      disabled={disabled}
      onPress={() => {
        dispatch(mixActions.finish(target));
      }}
    />
  );
};

export const FieldGenerator = () => {
  const generator = useAppSelector((state) => state.mix.generator);
  const { baseOnContrast } = useTheme();
  const item = useItemLayout(2);

  return (
    <View
      style={[item, styles.field, { backgroundColor: generator }]}
    >
      <Body text={"Gemeinsame Farbe"} color={baseOnContrast(generator)} />
    </View>
  );
};

export const FieldSecret = ({ target }: TargetProps) => {
  const secret = useAppSelector((state) => state.mix[target].secret);
  const name = target === "bob" ? "Bob" : "Alice";
  const { baseOnContrast } = useTheme();
  const item = useItemLayout();

  return (
    <View style={[item, styles.field, { backgroundColor: secret }]}>
      <Body text={name + " Geheim"} color={baseOnContrast(secret)} />
    </View>
  );
};

export const FieldMixed = ({ target }: TargetProps) => {
  const mixed = useAppSelector((state) => state.mix[target].mixed);
  const generator = useAppSelector((state) => state.mix.generator);
  const secret = useAppSelector((state) => state.mix[target].secret);
  const name = target === "bob" ? "Bob" : "Alice";
  const { baseOnContrast } = useTheme();
  const item = useItemLayout();

  return (
    <LinearGradient
      style={[item, styles.field]}
      colors={[generator, mixed, mixed, secret]}
      start={[0, 1]}
      end={[1, 0]}
    >
      <Body text={name + " Mix"} color={baseOnContrast(mixed)} />
    </LinearGradient>
  );
};

export const FieldReceived = ({ target }: TargetProps) => {
  const received = useAppSelector((state) => state.mix[target].received);
  const name = target === "bob" ? "Alice" : "Bob";
  const { baseOnContrast } = useTheme();
  const item = useItemLayout();

  return (
    <View style={[item, styles.field, { backgroundColor: received }]}>
      <Body text={name + " Mix"} color={baseOnContrast(received)} />
    </View>
  );
};

export const FieldResult = ({ target }: TargetProps) => {
  const received = useAppSelector((state) => state.mix[target].received);
  const result = useAppSelector((state) => state.mix[target].result);
  const secret = useAppSelector((state) => state.mix[target].secret);
  const { baseOnContrast } = useTheme();
  const item = useItemLayout();

  return (
    <LinearGradient
      style={[item, styles.field]}
      colors={[secret, result, result, received]}
      start={[0, 1]}
      end={[1, 0]}
    >
      <Body text={"Geteilt Geheim"} color={baseOnContrast(result)} />
    </LinearGradient>
  );
};
