import React, { useState } from "react";
import { InputBar } from "./InputBar";
import { useAppDispatch, useAppSelector, encrypt, chatActions } from "../store";
import { IconButton } from "./IconButton";

export const EncryptBar = () => {
  const dispatch = useAppDispatch();
  const key = useAppSelector((state) => state.crypto.key);
  const status = useAppSelector((state) => state.chat.status);
  const [text, setText] = useState("");
  const disabledSend = !text || status != "connected";
  const disabledCrypt = !text || !key;

  const handleSend = () => {
    if (!disabledSend) {
      dispatch(chatActions.send(text));
      setText("");
    }
  };
  const handleEncrypt = () => {
    if (!disabledCrypt && key) {
      setText(encrypt(text, key));
    }
  };

  return (
    <InputBar
      placeholder="Deine Nachricht"
      innerRight={
        <IconButton
          onPress={handleEncrypt}
          disabled={disabledCrypt}
          icon="Encrypt"
          size="huge"
        />
      }
      outerRight={
        <IconButton
          onPress={handleSend}
          disabled={disabledSend}
          gradient
          icon="Send"
        />
      }
      onChangeText={setText}
      onSubmitEditing={handleSend}
      value={text}
    />
  );
};
