import React, { ReactElement } from "react";
import { View, StyleSheet } from "react-native";
import { Sizing } from "../theme";
import { Chip } from "../theme/components";
import { Input, InputProps } from "./Input";

export interface InputBarProps extends InputProps {
  outerLeft?: ReactElement;
  innerLeft?: ReactElement;
  innerRight?: ReactElement;
  outerRight?: ReactElement;
}

export const InputBar = ({
  outerLeft,
  innerLeft,
  innerRight,
  outerRight,
  style,
  ...rest
}: InputBarProps) => {
  return (
    <View style={[styles.container, style]}>
      {outerLeft}
      <Chip
        style={[
          {
            marginLeft: outerLeft ? Sizing.layout.x5 : 0,
            marginRight: outerRight ? Sizing.layout.x5 : 0,
          },
          styles.inputBar,
        ]}
      >
        {innerLeft}
        <Input {...rest} style={[styles.inputBar]} />
        {innerRight}
      </Chip>
      {outerRight}
    </View>
  );
};

const styles = StyleSheet.create({
  inputBar: {
    flex: 1,
    height: Sizing.layout.x70,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
  },
});
