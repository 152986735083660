import React from "react";
import { Image, ScrollView, View } from "react-native";
import { Sizing, } from "../theme";
import { Body, Hint, Card, Content } from "../theme/components";
import { usePasswordGuessing } from "../hooks/usePasswordGuessing";
import { Spacer } from "../components/Spacer";
import { GuessInput, GuessList } from "./PwCrackingScreen1";

/**
 * Quickly generate some variations of password that can easily be guessed by
 * understanding the common patterns.
 * 
 * NOTE: This is super inefficient, but it's fine for this app context.
 */
const generate_passwords = () => {
  let base = [
    "Alice",
    "AliceCharlie",
    "Charlie",
    "CharlieDecember",
    "CharlieTennis",
    "BobRob",
    "Robertson",
    "RobertsonDecember",
    "ILoveAlice",
    "ILoveTennis",
    "SecurityAnalyst",
  ];
  // Append dates
  const datyfied = base.concat(base.map((pw) => pw.concat("1992")));
  // Append numbers
  const numberfied = datyfied.concat(datyfied.map((pw) => pw.concat("123")));
  // Append symbols
  const symbolfied = numberfied.concat(numberfied.map((pw) => pw.concat("!")))
  // Create l33t variants
  const l33tified1 = symbolfied.concat(symbolfied.map((pw) => {
    // NOTE: `replaceAll` does not exist on react native android..., use a workaround
    pw = pw.split("a").join("4");
    pw = pw.split("A").join("4");
    pw = pw.split("e").join("3");
    pw = pw.split("E").join("3");
    pw = pw.split("O").join("0");
    pw = pw.split("o").join("0");
    return pw;
  }))
  // Create l33t variants with $ and €
  const l33tified2 = l33tified1.concat(l33tified1.map((pw) => {
    pw = pw.split("E").join("€");
    pw = pw.split("e").join("€");
    pw = pw.split("S").join("$");
    pw = pw.split("s").join("$");
    return pw;
  }))
  // Create insensitive variants both with l33t and without
  const insenitives = l33tified2.concat(l33tified2.map((pw) => pw.toLowerCase()));

  // Finally remove all duplicates with a set
  return [...new Set(insenitives)];
}

// The final list of passwords we will use for this excercise.
const passwords = generate_passwords();

// Treat each rank the same for this excercise.
const scoring = (rank: number) => {
  return 1000;
};

export const PwCrackingScreen2 = () => {
  const { totalScore, makeGuess, guessedWords } = usePasswordGuessing(
    passwords,
    0,
    scoring
  );


  return (
    <Content>
      <Card style={{ height: "100%", paddingTop: 0, maxWidth: Sizing.cs(1500), alignSelf: "center" }}>
        <ScrollView>
          <Image
            style={{ maxWidth: "100%", height: Sizing.cs(400) }}
            resizeMode="contain"
            source={require("../../assets/profile.png")}
          />
          <Spacer size="small" />
          <View style={{ alignSelf: "center" }}>
            <Body text="Schaffst du es, die Passwörter von Bob zu knacken?" />
            <Hint text="Benutze was du über herkömmliche Passwortmuster gelernt hast." />
          </View>
          <GuessList guesses={guessedWords} />
        </ScrollView>
        <Spacer size="small" />
        <GuessInput makeGuess={makeGuess} totalScore={totalScore} />
      </Card>
    </Content>
  );
};
