import React from "react";
import {
  createMaterialTopTabNavigator,
  MaterialTopTabBar,
} from "@react-navigation/material-top-tabs";
import { Platform } from "react-native";
import { ChatScreen, CryptoScreen, MixScreen } from "../screens";
import { Sizing, Typography, useTheme } from "../theme";
import { RootTabParamList } from "./types";
import { useDeviceOrientation } from "@react-native-community/hooks";
import { Icons } from "../theme/icons";

// The actual Tab navigator
export const Tab = createMaterialTopTabNavigator<RootTabParamList>();

export const KeyExchangeTabs = () => {
  const isLandscape = useDeviceOrientation().landscape;
  const isWeb = Platform.OS === "web";
  const { colors } = useTheme();

  return (
    <Tab.Navigator
      initialRouteName="MixScreen"
      tabBar={MaterialTopTabBar}
      screenOptions={({ route }) => ({
        // Swiping with a mouse result in unintended actions and an annoyed user
        swipeEnabled: !isWeb,
        tabBarStyle: {
          elevation: 0,
          shadowOpacity: 0,
        },
        tabBarLabelStyle: Typography.text.body,
        tabBarContentContainerStyle: {
          height: isLandscape ? Sizing.layout.x70 : Sizing.layout.x70 * 2,
          alignItems: "center",
        },
        tabBarItemStyle: {
          // Decide if to place icon next or above the label
          flexDirection: isLandscape ? "row" : "column",
        },
        tabBarIconStyle: {
          alignItems: "center",
          justifyContent: "center",
          // Web Version places no margin between label
          marginRight: isWeb ? Sizing.layout.x30 : 0,
        },
      })}
    >
      <Tab.Screen
        name="MixScreen"
        component={MixScreen}
        options={{
          tabBarLabel: "Mischung",
          tabBarIcon: ({ focused, color }) => (
            <Icons.MixScreen
              color={focused ? colors.primary.s200 : color}
              size="huge"
            />
          ),
        }}
      />
      <Tab.Screen
        name="CryptoScreen"
        component={CryptoScreen}
        options={{
          tabBarLabel: "Crypto",
          tabBarIcon: ({ focused, color }) => (
            <Icons.CryptoScreen
              color={focused ? colors.primary.s200 : color}
              size="huge"
            />
          ),
        }}
      />
      <Tab.Screen
        name="ChatScreen"
        component={ChatScreen}
        options={{
          tabBarLabel: "Chat",
          tabBarIcon: ({ focused, color }) => (
            <Icons.ChatTab
              color={focused ? colors.primary.s200 : color}
              size="huge"
            />
          ),
        }}
      />
    </Tab.Navigator>
  );
};
