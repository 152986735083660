import { useState } from "react";
import { notify } from "../styles/notification";

export interface PasswordGuess {
  rank: number;
  password: string;
  score: number;
  hidden: boolean;
}

function Top10Notification(rank: number, score: number) {
  notify({
    message: "Passwort auf Platz " + rank + "!",
    description: "" + score + " Punkte hinzugefügt",
    type: "success",
  });
}

function Top1000Notification(rank: number, score: number) {
  notify({
    message: "Passwort auf Platz " + rank,
    description: "" + score + " Punkte hinzugefügt",
    type: "info",
  });
}

function AlreadyGuessNotification(rank: number) {
  notify({
    message: "Schon geraten",
    description: "Auf Platz " + rank,
    type: "warning",
  });
}

function NotFoundNotification(num: number) {
  notify({
    message: "Nicht in Top " + num,
    type: "danger",
  });
}

export const usePasswordGuessing = (
  passwords: string[],
  numPlaceholders: number,
  scoring: (rank: number) => number,
) => {
  const placeholders = passwords
    .slice(0, numPlaceholders)
    .map((password, index) => {
      const rank = index + 1;
      const score = scoring(rank);
      return {
        rank,
        password,
        score,
        hidden: true,
      } as PasswordGuess;
    });

  const [guessedWords, setGuessedWords] = useState(placeholders);
  const [totalScore, setTotalScore] = useState(0);

  const makeGuess = (guess: string) => {
    const guessScore = checkGuess(guess);
    setTotalScore(totalScore + guessScore);
  };

  const checkGuess = (guess: string): number => {
    // Check if guess is in list
    const index = passwords.findIndex(
      (pw) => pw === guess
    );
    if (index < 0) {
      NotFoundNotification(passwords.length);
      return 0;
    }

    const guessedRank = index + 1;

    // Check if guess already exists or is an unrevealed placeholders
    const entry = guessedWords.find(({ rank }) => rank == guessedRank);

    // Is in placeholders, reveal the hidden entry
    if (entry && entry.hidden) {
      Top10Notification(guessedRank, entry.score);
      setGuessedWords(
        guessedWords.map((old) =>
          old.rank === guessedRank ? { ...entry, hidden: false } : old
        )
      );
      return entry.score;
    }
    // Wasn't guessed already, add to the list
    if (!entry) {
      const newScore = scoring(guessedRank);
      Top1000Notification(guessedRank, newScore);
      setGuessedWords([
        ...guessedWords,
        {
          rank: guessedRank,
          password: guess,
          score: newScore,
          hidden: false,
        },
      ]);
      return newScore;
    }

    // Was guessed already, do nothing
    AlreadyGuessNotification(guessedRank);

    return 0;
  };

  return { totalScore, makeGuess, guessedWords };
};

