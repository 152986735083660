import { Platform } from "react-native"
import { moderateScale } from "react-native-size-matters"

/// Tweak the base sizes to adjust scaling between different screens
export const customScale = (size: number) => {
    return Platform.OS === "web" ? size : moderateScale(size / 2, 0.5);
}
/// Alias for custom scale
export const cs = customScale;

type Layout =
    | "x5"
    | "x10"
    | "x15"
    | "x20"
    | "x30"
    | "x40"
    | "x50"
    | "x60"
    | "x70"
    | "x80"
    | "x90"
    | "x100"
    | "x110"
    | "x120"
    | "x130"
    | "x140"
export const layout: Record<Layout, number> = {
    x5: cs(5),
    x10: cs(10),
    x15: cs(14),
    x20: cs(18),
    x30: cs(26),
    x40: cs(34),
    x50: cs(42),
    x60: cs(50),
    x70: cs(64),
    x80: cs(86),
    x90: cs(120),
    x100: cs(130),
    x110: cs(150),
    x120: cs(170),
    x130: cs(200),
    x140: cs(230),
}
