import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import mix from "./MixReducer";
import crypto from "./CryptoReducer"
import chat, { socketMiddleware } from "./ChatReducer"
import { loggerMiddleware } from "./LoggerMiddleware";

// Additional middleware
const middleware = [socketMiddleware]
// Middleware for development only, e.g. due to performance reasons
const devMiddleware = [loggerMiddleware]

// Combine all reducers and middleware into the global store
export const store = configureStore({
    reducer: {
        mix,
        crypto,
        chat,
    },
    middleware: (getDefaultMiddleware) =>
        // Deactivate serializableCheck due to bigint not being serializable
        getDefaultMiddleware({ serializableCheck: false })
            .concat(middleware)
            .concat(__DEV__ ? devMiddleware : []),
});

// Infer the RootState and AppDispatch from the store itself.
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Reexport all slice functionality
export * from "./ChatReducer"
export * from "./CryptoReducer"
export * from "./MixReducer"
