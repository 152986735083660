/**
 * The type of a body within a chat message.
 */
export type MessageBody = string;

/**
 * The type that identifies a given user.
 * Currently only uses the username as they are unique.
 */
export type UserInfo = string;

/**
 * The unique ID for a given event of any kind.
 */
export type EventID = string;

/**
 * The type and format in which timestamps are stored.
 * Currently uses a UNIX timestamp that has to be converted into a readible format.
 */
export type DateType = number;

/**
 * Helpful type mappings for the json enum error codes.
 * An `ErrorCode` defines the kind of error that occurred within the subprotocol.
 * 
 * Thes values need to match the expected JSON on server side.
 */
export enum ErrorCode {
    NameTaken = "nameTaken",
    NameTooShort = "nameTooShort",
    NameInvalid = "nameInvalid",
    InputInvalid = "inputInvalid",
    TimeOut = "timeOut",
    ConnectionLost = "connectionLost",
}

/**
 * Helpful type mappings for distinction between incoming server sent messages.
 * 
 * The values need to match the expected JSON on server side.
 */
export enum IncomingType {
    Error = "error",
    Registered = "registered",
    ChatMessage = "message",
    UserConnected = "userConnected",
    UserDisconnected = "userDisconnected",
}

/**
 * Wraps an incoming registered event with the necessary protocol type
 * categorization for deserialization.
 */
export interface RegisteredEvent {
    type: IncomingType.Registered,
    payload: UserInfo,
}

/**
 * A `MessageEvent` provides information about a chat message including the author,
 * a timestamp and the message itself.
 */
export interface ChatMessage {
    id: EventID,
    received_at: DateType,
    body: MessageBody,
    author: UserInfo,
}

/**
 * Wraps an incoming chat message event with the necessary protocol type
 * categorization for deserialization.
 */
export interface ChatMessageEvent {
    type: IncomingType.ChatMessage,
    payload: ChatMessage,
}

/**
 * Wraps an errorcode with the necessary JSON prefix.
 */
export interface ProtocolError {
    code: ErrorCode,
}

/**
 * Wraps an incoming `ProtocolError` with the necessary protocol type
 * categorization for deserialization.
 */
export interface ErrorEvent {
    type: IncomingType.Error,
    payload: ProtocolError,
}

/**
 * A `UserEvent` encapsulates the common information that the different user
 * actions require.
 * Most importantly the `UserInfo` and timestamp the event was received at
 * by the server.
 */
export interface UserEvent {
    id: EventID,
    received_at: DateType,
    user: UserInfo,
}

/**
 * Wraps an incoming user connected event with the necessary protocol type
 * categorization for deserialization.
 */
export interface UserConnectedEvent {
    type: IncomingType.UserConnected,
    payload: UserEvent,
}

/**
 * Wraps an incoming user disconnected event with the necessary protocol type
 * categorization for deserialization.
 */
export interface UserDisconnectedEvent {
    type: IncomingType.UserDisconnected,
    payload: UserEvent,
}

/**
 * A `ChatEvent` is any kind of event that gets displayed within the chat in
 * some form.
 */
export type ChatEvent = ChatMessageEvent | UserConnectedEvent | UserDisconnectedEvent

/**
 * An `IncomingEvent` is the combination of all possible protocol events
 * received from a chat server.
 */
export type IncomingEvent =
    ErrorEvent |
    RegisteredEvent |
    ChatEvent;

/**
 * Helpful type mappings for distinction between outgoing user sent messages.
 */
export enum OutgoingType {
    Message = "message",
    KeepAlive = "keepAlive",
}

/**
 * Wraps an outgoing chat message with the necessary protocol type categorization
 * for serialization.
 */
export class PostChatMessageEvent {
    type = OutgoingType.Message;
    payload: MessageBody;

    constructor(msg: MessageBody) {
        this.payload = msg;
    }
}

/**
 * Wraps an outgoing keep alive event with the necessary protocol type for
 * serialization.
 */
export class KeepAliveEvent {
    type = OutgoingType.KeepAlive;
}

/**
 * Combination of all outgoing message events.
 */
export type OutgoingMessage =
    | PostChatMessageEvent
    | KeepAliveEvent;
