import { useTheme } from "./ThemeProvider";
import {
    Linking,
    Text as RNText,
    TextProps as RNTextProps,
    View,
    ViewProps
} from "react-native";
import React from "react";
import { FontSize, fontSize, lineHeight, text } from "./typography";
import { layout } from "./styles";

export interface TextProps extends RNTextProps {
    size?: FontSize;
    color?: string;
    text?: string;
}

export const Text = ({
    style, size, color, text, children, ...rest
}: TextProps) => {
    return (
        <RNText
            style={[
                { color, flexWrap: "wrap" },
                fontSize[size ?? "x50"],
                lineHeight[size ?? "x50"],
                style,
            ]}
            {...rest}
        >
            {text}
            {children}
        </RNText>
    );
};

export const Header = ({ style, size, color, ...rest }: TextProps) => {
    const { colors } = useTheme();
    return (
        <Text
            color={color ?? colors.foreground.s300}
            style={[text.header, style]}
            {...rest} />
    );
};

export const SubHeader = ({ style, color, size, ...rest }: TextProps) => {
    const { colors } = useTheme();
    return (
        <Text
            color={color ?? colors.foreground.s300}
            style={[text.subHeader, style]}
            {...rest} />
    );
};

export const Highlight = ({ style, size, color, ...rest }: TextProps) => {
    const { colors } = useTheme();
    return (
        <Text
            color={color ?? colors.foreground.s300}
            style={[text.highlight, style]}
            {...rest} />
    );
};

export const Body = ({ style, size, color, ...rest }: TextProps) => {
    const { colors } = useTheme();
    return (
        <Text
            color={color ?? colors.foreground.s300}
            style={[text.body, style]}
            {...rest} />
    );
};

export const Hint = ({ style, color, size, ...rest }: TextProps) => {
    const { colors } = useTheme();
    return (
        <Text
            color={color ?? colors.foreground.s200}
            style={[text.hint, style]}
            {...rest} />
    );
};

export const Footnote = ({ style, size, color, ...rest }: TextProps) => {
    const { colors } = useTheme();
    return (
        <Text
            color={color ?? colors.foreground.s200}
            style={[text.footnote, style]}
            {...rest} />
    );
};

export interface LinkProps extends TextProps {
    url: string;
}

export const Link = ({ style, url, ...props }: LinkProps) => {
    const { colors } = useTheme();
    return (
        <Text
            style={[{ color: colors.secondary.s200 }, text.link, style]}
            onPress={() => Linking.openURL(url)}
            {...props} />
    );
};

export const Card = ({ style, ...props }: ViewProps) => {
    const { themeStyles } = useTheme();
    return <View {...props} style={[themeStyles.card, style,]} />;
};

export const Background = ({ style, ...props }: ViewProps) => {
    const { themeStyles } = useTheme();
    return <View style={[themeStyles.background, style]} {...props} />
}

export const Chip = ({ style, ...props }: ViewProps) => {
    const { themeStyles } = useTheme();
    return <View style={[themeStyles.chip, style]} {...props} />;
};

export const Content = ({ style, ...props }: ViewProps) => {
    return <View style={[layout.contentContainer, style]} {...props} />;
};

export const FlexContent = ({ style, ...props }: ViewProps) => {
    return <View style={[layout.flexContent, style]} {...props} />;
};
