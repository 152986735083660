/**
 * The domain of the backend API.
 */
export const BASE_PATH = "chatservice.mintmobil.eu"

/**
 * The endpoint used to connect the websocket to.
 */
export const CHAT_ENDPOINT = "wss://" + BASE_PATH + "/chat/"

/**
 * Time in milliseconds until a keep alive message is periodically sent.
 * Has to be lower than the configured keep alive time of the chat server to
 * prevent disconnects.
 */
export const KEEP_ALIVE_INTERVAL = 3000

/**
 * The HTTP error code indicating that an error within the custom websocket
 * subpprotocol occurred.
 */
export const PROTOCOL_ERROR_CODE = 1002
